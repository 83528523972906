import { createContext, useContext } from "react";
import * as SessionStorageWrapper from "../utils/SessionStorageWrapper"

export const CartContext = createContext();

export function useCartContext() {
  return useContext(CartContext);
}

const cartFields = [
  "id",
  "token",
  "auto_discount",
  "step",
  "image_suffix",
  "item_title",
  "item_quantity",
  "product_id",
  "subscription_discount",
  "coupon_discount",
  "product_item_plans",
  "is_otc",
  "price",
  "category",
  "subscription_plan_id",
  "total_price",
  "product_dosage",
  "order_id",
  "mixpanel_title",
  "is_e_recipe"
];

export function loadCart() {
  let cart = {};
  const urlParams = new URLSearchParams(window.location.search)
  const cartFromParam = urlParams.get('crt');

  // if there is cart in url and was not shipped yet
  //TODO: remove this, beacuse cms doen't send anymore to inner cart links
  if(cartFromParam && SessionStorageWrapper.getItem("last_shipped_cart") !== cartFromParam){
    window.eventsTracking.setData(`cart_id`, cartFromParam, true);
  }
  try{
  cartFields.forEach((field) => {
    
    let value = window.eventsTracking.getData(`cart_${field}`);
    if (value) {
      cart[field] = value;
  }
  })
  }catch(err){};

  return cart;
}

export function clearCart() {
  cartFields.forEach((field) => {
    let value = window.eventsTracking.getData(`cart_${field}`);
    if (value) {
      window.eventsTracking.setData(`cart_${field}`, "", true);
    }
  });
}

export const storeCart = (cart) => {
  Object.keys(cart).forEach((field) => {
    if (!cartFields.includes(field)) {
      throw new Error(`${field} does not exists in cartFields`);
    }
    window.eventsTracking.setData(`cart_${field}`, cart[field], true);
  });
};

export const CartReducer = (state, action) => {
  switch (action.type) {
    case "store":
      let newState = {
        ...state,
      };

      storeCart(action.cart);

      return newState;
    default:
      return state;
  }
};
