import React from 'react';
import "./PharmacySelectionConfirmationModal.css";

interface Props{
    closeModal: () => void
    onSubmit: () => void
    selectedPharmacy: {name:string, address:string}
}

const PharmacySelectionConfirmationModal:React.FC<Props> = ({closeModal,onSubmit,selectedPharmacy}) => {
  return (
    <div onClick={closeModal} className="pharmacy-selection-modal-background">
        <div 
        className="pharmacy-selection-modal-container"
        onClick={(event) => event.stopPropagation()}
        >
            <img onClick={closeModal} className="close-btn" src={require("../../../../src/icons/X.svg").default} alt="X" />
            <h6>Sie haben diese Apotheke zur Abwicklung Ihrer Bestellung ausgewählt:</h6>
            <ul>
                <li>{selectedPharmacy!.name}</li>
                <li>{selectedPharmacy!.address}</li>
            </ul>
            
            <p className='pharmacy-selection-disclaimer'>
                Klicken Sie auf „Bestätigen“, damit wir Ihr Rezept an die ausgewählte Apotheke senden können, sobald es vom Arzt genehmigt wurde. Sie werden per E-Mail über die Abholung der Bestellung benachrichtigt werden. 
            </p>

            <div className="actions-container">
                <span className="confirm-btn" onClick={onSubmit}>Bestätigen</span>
                <span className='cancel-btn' onClick={closeModal}>Stornieren</span>
            </div>
        </div>
    </div>
  )
}

export default PharmacySelectionConfirmationModal