import React, { useContext } from 'react'
import { ChooseTreatmentContext, Product, ProductItem, toLocalePrice } from '../FreeDosageLayout';
import { RouterCartContext } from '../../ChooseTreatmentRouter';

interface IProductQuantityControlProps {
    items: ProductItem[],
}


const getMaxPricePerQuantity = (product: Product | null) => {
    if (!product) return 0;

    if (product.items?.length > 0) {
        const firstItem = product.items[0];
        return firstItem?.price / firstItem?.quantity;
    };

    return 0;
}

const ProductQuantityControl: React.FC<IProductQuantityControlProps> = ({ items = [] }) => {

    const chooseTreatmentContext = useContext(ChooseTreatmentContext);
    const index = items.findIndex((item) => item.product_item_id === chooseTreatmentContext?.selectedProductItemID) || 0;
    const selectedProduct = chooseTreatmentContext?.selectedProduct || null;
    const selectedProductItem = chooseTreatmentContext?.selectedProductItem || null;
    const maxPerGramCost = getMaxPricePerQuantity(selectedProduct);
    const routerCartContext:any = useContext(RouterCartContext)
    const cart = routerCartContext.cartData
    const isSelfPickup = cart?.self_pickup_pharmacy_id ? true : false

    const nextProduct = () => {
        if (index < items.length - 1) {
            chooseTreatmentContext?.setSelectedProductItemID(items[index + 1].product_item_id);
        }
    }

    const previousProduct = () => {
        if (index > 0) {
            chooseTreatmentContext?.setSelectedProductItemID(items[index - 1].product_item_id);
        }
    }


    const calculateSave = () => {
        if (!selectedProductItem) {
            return 0;
        }

        const savePrice = selectedProductItem?.quantity * maxPerGramCost - selectedProductItem?.price;
        if (savePrice > 0)
            return toLocalePrice(savePrice);
    }

    const savedCost = calculateSave();

    return (
        <>
            {
                (items.length && selectedProductItem) ?
                    <div id='quantity-control' className='p-5'>
                        {/* text intro??? */}
                        {
                            selectedProduct?.in_stock ? <div className='mb-4'>
                                <p className='text-md font-bold text-[#0D2C54]'>Menge wählen</p>
                                {selectedProduct?.quantity_type == 'gram' &&
                                    <p className='text-sm text-[#3D5676]'>* Füllmengen in 5-Gramm-Schritten. 
                                        {!isSelfPickup ? "Je höher die Dosis, desto geringer die Kosten." : ""}
                                    </p>
                                }
                            </div> :
                                <div className='mb-4'>
                                    <p className='text-md font-bold text-[#0D2C54]'>Nur Rezept</p>
                                </div>
                        }



                        <div className='flex items-start flex-1 justify-around '>

                            <div className='flex-1 w-9/12'>
                                <div style={{
                                    border: "1px solid #E5E5E5",
                                }} className='flex justify-between items-center py-1 rounded-md px-2'>
                                    <div onClick={previousProduct} className='cursor-pointer select-none rounded-md h-8 w-8 text-white bg-[#11DDAC] text-center flex items-center justify-center hover:opacity-90 transition-opacity'><div id='sub_quant'>-</div></div>
                                    <div className='flex flex-col justify-center items-center'>
                                        <p className='text-[#0D2C54] font-normal'>{selectedProductItem?.quantity}</p>
                                        <p className='text-[#98A0AF] text-sm'>{selectedProduct?.quantity_type == 'gram' ? 'Gramm' : 'Milliliter'}</p>
                                    </div>
                                    <div onClick={nextProduct} className='cursor-pointer select-none rounded-md h-8 w-8 text-white bg-[#11DDAC] text-center flex items-center justify-center hover:opacity-90 transition-opacity'><div id="add_quant">+</div></div>
                                </div>

                                {
                                    selectedProduct?.in_stock ?
                                    <div className='mt-2 flex items-end justify-center gap-1'>
                                        {selectedProduct?.quantity_type == 'gram' &&
                                            <>
                                                <p className='text-[#0D2C54] text-sm font-normal'>{toLocalePrice(selectedProductItem?.price / selectedProductItem?.quantity)}</p>
                                                <p className='text-xs text-[#98A0AF]'>pro Gramm</p>
                                            </>
                                        }
                                    </div> : null
                                }
                            </div>

                            <div className='_pricing p-1 w-3/12 text-center'>
                                <p className='text-[#0D2C54] font-medium text-right'>{selectedProduct?.in_stock ? toLocalePrice(selectedProductItem?.price) : toLocalePrice(selectedProduct?.treatment_fee!)} €</p>
                                {savedCost && selectedProductItem?.in_stock ?
                                    <p className='text-xs font-medium text-nowrap text-[#0A9281]'>Spare {savedCost} €</p> : null
                                }
                            </div>
                        </div>

                       
                    </div> : null

            }
        </>
    )
}

export default ProductQuantityControl