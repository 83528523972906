import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import * as translator from "../../utils/translator";
import HeaderCMS from '../../components/Headers/HeaderCMS/HeaderCMS';
import headphones from "../../images/headphones-contact-form.png";
import ContactPageForm from './components/ContactPageForm';
import SupportTicketSection from './components/SupportTicketSection';
import Footer from '../../components/Footer/Footer';
import StickyNotification from '../../components/StickyNotification/StickyNotification';
import Disclaimer from '../ChooseTreatmentVariants/components/Disclaimers/Disclaimer';


const ContactPage = () => {
  const { country } = useParams();
  const language = translator.getLanguageByCountry(country!);
  const navigate = useNavigate();

  const [showStickyNotification, setShowStickyNotification] = useState({
    show: false,
    title: "",
    content: "",
    level: ""
  });

  return (
    <>
      <StickyNotification
        title={showStickyNotification.title}
        content={showStickyNotification.content}
        onClick={() => setShowStickyNotification({ show: false, title: "", content: "", level: "error" })}
        key={5}
        level='error'
        showNotification={showStickyNotification.show}
      />

      <HeaderCMS country={country!} />
      <div id='contact-page-container' className=' flex justify-center px-4 md:px-10 pt-10 gap-10 bg-[#F7F7F7] min-h-screen pb-[200px]'>
        <div id='contact-page' className='w-full md:w-[1000px] flex flex-col items-center' >

          <div className='w-full select-none'>
            <a onClick={() => navigate(-1)} className='text-left text-sm max-md:hidden text-[#11DDAC] underline hover:no-underline cursor-pointer font-md '>
              {translator.translate(language, "contact", "ContactFormPage/back")}
            </a>
          </div>

          <img id='headphones-img' className='w-60 h-60 md:hidden' src={headphones} alt="" />

          <h1 id='contact-form-title' className='font-poppins text-[#0D2C54] text-[24px] md:text-[32px] font-[500] md:leading-[48px] text-left py-10 w-full'>{translator.translate(language, "contact", "ContactFormPage/title")}</h1>

          <SupportTicketSection language={language} />

          {language === "de" &&
            <div id='contact-page-disclaimer' className='pt-10 flex justify-center'>
              <Disclaimer languageID={language} field="contact" text="HeaderMessage/disclaimer" containerClassName='md:w-3/5' textClassName='font-[400]' />
            </div>
          }


          <ContactPageForm toggleStickyNotification={setShowStickyNotification} />
        </div>
      </div>

      <Footer page='questionnaire' />
    </>

  )
}

export default ContactPage