import DHLPackstationShipping from "../DHLPackstationShipping/DHLPackstationShipping";
import PersonalAdderssShipping from "../PersonalAdderssShipping/PersonalAdderssShipping";
import PaymentOptionCard from "../PaymentOptionCard/PaymentOptionCard";
import { ShippingDataContext } from "../../Shipping";
import * as translator from "../../../../utils/translator";
import { useContext, useEffect } from "react";
import { DE_ONLINE_PAYMENT_IMAGES, FR_ONLINE_PAYMENT_IMAGES } from "../../helpers";
import SelftPickupShipping from "../SelfPickupShipping/SelfPickupShipping";
// const ConfigManager = require("funnel-common").ConfigManager;
//import * as ConfigManager from "../../../../../../funnel-common/src/ConfigManager/index";

//shipping constants
const PERSONAL_ADDRESS_METHOD_INDEX = 1;
const DHL_ADDRESS_METHOD_INDEX = 2;

//payment constants
const ONLINE_PAYMENT_OPTION_INDEX = 0;


const ShippingLayout = ({
  productCatalog,
  isOnlyPrescription,
  isSubscriptionFlow,
  isTreatmentOTC,
  updatePrescriptionOnly,
  selectedDeliveryMethodIndex,
  orderWindowDetails,
  updateDeliveryMethodID,
  pageState,
  onDeliveryMethodChange,
  isMethodSelected,
  setPrescriptionOnly,
  selectedDeliveryMethodID,
  selectedPaymentMethod,
  paymentImages,
  onSubmit,
  onPaymentMethodChange,
  generateOrderWindow,
  selectedPersonalShippingIndex,
  updateSelectedPersonalShippingIndex,
  children,
  codSupport,
  wireSupport,
  cartID,
}) => {
  const {
    country,
    language,
    prescriptionOnly,
    shippingMethodsByPriority,
    minimalShippingMethodPriorty,
    limitToPrescriptionOnly,
    destinationCountry,
    adyenDropInMethodID,
    DBcart,
    selfPickupPharmacy
  } = useContext(ShippingDataContext);


  useEffect(() => {
    onPaymentMethodChange(ONLINE_PAYMENT_OPTION_INDEX);
  }, [prescriptionOnly, destinationCountry]);


  return (
    <>
      <p className="shipping-instruction-title">
        {translator.translate(language, "shipping", "layout/shipping_title")}
      </p>
      <div className="shipping-container">


        {
          selfPickupPharmacy ?
            <SelftPickupShipping />
            :
            <PersonalAdderssShipping
              allowPrescriptionOnly={!isSubscriptionFlow && !isTreatmentOTC && destinationCountry != 'ch' && !DBcart.is_e_recipe}
              onClick={() => onDeliveryMethodChange(PERSONAL_ADDRESS_METHOD_INDEX, shippingMethodsByPriority[minimalShippingMethodPriorty].id)}
              updatePrescriptionOnly={updatePrescriptionOnly}
              pageState={pageState}
              selected={selectedDeliveryMethodID === shippingMethodsByPriority[minimalShippingMethodPriorty].id && isMethodSelected(selectedDeliveryMethodIndex, PERSONAL_ADDRESS_METHOD_INDEX)}
              params={{ price: String(orderWindowDetails.treatmentFee.toFixed(2)) + "€" }}
              updateDeliveryMethodID={updateDeliveryMethodID}
              selectedPersonalShippingIndex={selectedPersonalShippingIndex}
              updateSelectedPersonalShippingIndex={updateSelectedPersonalShippingIndex}
              cartID={cartID}
            />
        }


        {
          selfPickupPharmacy || limitToPrescriptionOnly || country.toLowerCase() === "fr" ? null : (
            <DHLPackstationShipping
              disabled={
                isOnlyPrescription || productCatalog.disable_packstation
              }
              onClick={() => {
                onDeliveryMethodChange(DHL_ADDRESS_METHOD_INDEX, shippingMethodsByPriority[minimalShippingMethodPriorty].id);
                setPrescriptionOnly(false);
                updateSelectedPersonalShippingIndex(0);
              }}
              isOnlyPrescription={isOnlyPrescription}
              selected={selectedDeliveryMethodID == shippingMethodsByPriority[minimalShippingMethodPriorty].id && isMethodSelected(selectedDeliveryMethodIndex, DHL_ADDRESS_METHOD_INDEX)}
            />
          )
        }

      </div>

      {/* insert orderwindow here */}
      <div className="countryLayout-orderWindow">{children}</div>

      <div className="payment-info-container">
        {/* ONLINE PAYMENT  */}
        <PaymentOptionCard
          icons={country.toLowerCase() == "de" ? DE_ONLINE_PAYMENT_IMAGES : FR_ONLINE_PAYMENT_IMAGES}
          onSubmit={() =>
            onSubmit(adyenDropInMethodID, "ONLINE", selectedDeliveryMethodID)
          }
          paymentTypeID={adyenDropInMethodID} //Online payment
          title={translator.translate(
            language,
            "shipping",
            "paymentMehods/online/title"
          )}
          description={translator.translate(
            language,
            "shipping",
            "paymentMehods/online/description"
          )}
          onClick={() => onPaymentMethodChange(ONLINE_PAYMENT_OPTION_INDEX)}
          selected={isMethodSelected(
            selectedPaymentMethod,
            ONLINE_PAYMENT_OPTION_INDEX
          )}
          longIconList
        />

      </div>
    </>
  );
};

export default ShippingLayout;
