import React, { useState, useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import AskADoctorInputField from '../AskADoctorInputField/AskADoctorInputField';
import ChatMessage from '../ChatMessage/ChatMessage';
import ConversationTabs from '../ConversationTabs/ConversationTabs';
import "./Conversation.css"
import * as http from "../../../../utils/http";
import useProductsAutoCompletion from "../../customHooks/useProductsAutoCompletion";
import * as translator from "../../../../utils/translator";

interface ConversationProps {
  conversationMessages: any[]; // the original conversation messages that came from db (before client sends a new one)
  initialConversationProductsMentions: ConversationMention[]; // all the products that were mentioned in the conversation
  initialConversationOrdersMentions: ConversationMention[]; // all the orders that were mentioned in the conversation
  initialConversationAttachmentsMentions: ConversationMention[]; // all the Attachments that were mentioned in the conversation
  searchProducts: ConversationMention[]; // all the product categories from db that can be mentioned in input.
  searchOrders: ConversationMention[];// all the orders categories from db that can be mentioned in input.
  isActive?: boolean; //if the conversation has no deleted at
  selectedIssue?: string;
  selectedIssueTranslate?: string;
}

type ConversationMention = {
  mention_type: string;
  mention_id: number;
  mention_text: string;
  mention_link?: string;
};

const Conversation: React.FC<ConversationProps> = ({
  conversationMessages,
  initialConversationProductsMentions,
  initialConversationOrdersMentions,
  initialConversationAttachmentsMentions,
  searchProducts,
  searchOrders,
  isActive = false,
  selectedIssue = "",
  selectedIssueTranslate = ""
}) => {

  const { setRefreshData, setStickyNotificationDetails, myAccountData, language } = useOutletContext<any>();
  const [inputText, setInputText] = useState("");
  const chatHistoryRef = useRef<HTMLDivElement>(null); //mainly for scrolling down on new message send

  useEffect(() => {
    // Scroll to the bottom of the chat history div
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [conversationMessages]);


  const onAutoCompletionSelect = (mention: ConversationMention) => {
    let lastMentionedSymbolStart = -1;
    let symbolToInsert = '';

    if (mention.mention_type.includes('Order')) {
      lastMentionedSymbolStart = inputText.lastIndexOf('#');
      symbolToInsert = '#';
    } else {
      lastMentionedSymbolStart = inputText.lastIndexOf('@');
      symbolToInsert = '@';
    }

    setInputText((currentInput) =>
      currentInput.slice(0, lastMentionedSymbolStart) + symbolToInsert + mention.mention_text
    );
  };

  const productsAutoCompletionHook = useProductsAutoCompletion(
    searchProducts,
    searchOrders,
    onAutoCompletionSelect //the logic of what happens onAutoCompletionSelect
  );

  const processInputText = (inputText: string) => {
    const newMentionedProducts: ConversationMention[] = [];
    const newMentionedOrders: ConversationMention[] = [];

    searchProducts.forEach((product: ConversationMention) => {
      if (inputText.includes(`@${product.mention_text}`)) {
        newMentionedProducts.push(product);
      }
    });

    searchOrders.forEach((order: ConversationMention) => {
      if (inputText.includes(`#${order.mention_text}`)) {
        newMentionedOrders.push(order);
      }
    });

    return { newMentionedProducts, newMentionedOrders };
  };


  const onSend = (files: any) => {
    let filesToSend: any[] = [];
    if (files.length !== 0) {
      filesToSend.push(...files);
    }

    if (!inputText) {
      // TODO: pop up a sticky notification with an alert
      return;
    }

    let { newMentionedProducts, newMentionedOrders } = processInputText(inputText);

    http
      .postMultiPartsToServer(
        `account-update/conversations`,
        {
          inputText,
          isFirstPost: conversationMessages.length === 0,
          country: myAccountData.locale.country_id,
          language: myAccountData.locale.language_id,
          mentionedProducts: newMentionedProducts,
          mentionedOrders: newMentionedOrders,
          selectedIssue
        },
        filesToSend,
        [http.MIDDLEWARES.CUSTOMER_ID]
      )
      .then((response: any) => {
        setInputText("");
        setRefreshData((current: boolean) => !current);
      })
      .catch((error) => {
        setStickyNotificationDetails((currentDetails: any) => ({
          show: true,
          title: "",
          content: translator.translate(
            language,
            "my_account",
            `stickyNotifications/error/${error.response.data}`
          ),
          level: "error",
          showInfoIcon: false,
          refresh: !currentDetails.refresh,
        }));
      });
  };

  const onTextChange = (text: string) => {
    setInputText(text);
    productsAutoCompletionHook.onTextChange(text); //Updating the auto complete hook so it will listen to "@,#" changes
  };
  return (
    <>
      {/* current/recent conversation with doctor */}
      <div className="conversation-container">
        <div className={conversationMessages.length ? "chat-and-input-margin-off" : "chat-and-input"}>

          {
            conversationMessages.length > 0 && (
              <div>
                {isActive && selectedIssue && ( 
                  <div className="subject-title-filled">
                    {selectedIssueTranslate}
                  </div>
                )}
                <div className="chat-history" ref={chatHistoryRef}>
                  {conversationMessages.map((messageData, index) => (
                    <div className="chat-message" key={index}>
                      <ChatMessage
                        messageData={messageData}
                        language={language}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )
          }


          {
            isActive ?  //The inputfield is only visible in active conversations
              <AskADoctorInputField
                showAutoComplete={productsAutoCompletionHook.showAutoComplete}
                autoCompleteOptions={productsAutoCompletionHook.autoCompleteOptions}
                value={inputText}
                onChange={onTextChange}
                onSend={onSend}
                onAutoCompletionSelect={
                  productsAutoCompletionHook.onAutoCompletionSelect
                }
                prefixInputState={productsAutoCompletionHook.prefixInputState}
              />
              :
              null
          }


        </div>

        {conversationMessages.length ? (
          <ConversationTabs
            initialConversationProductsMentions={
              initialConversationProductsMentions
            }
            initialConversationOrdersMentions={
              initialConversationOrdersMentions
            }
            initialConversationAttachmentsMentions={
              initialConversationAttachmentsMentions
            }
          />
        ) : null}
      </div>
    </>
  );
};

export default Conversation;
