import { PropsWithChildren } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import ServerError from "../ServerError/ServerError";

type ErrorProps = {};

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return <ServerError status={422} />;
}

export const CustomErrorBoundary = (props: PropsWithChildren<ErrorProps>) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {props.children}
    </ErrorBoundary>
  );
};
