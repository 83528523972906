import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { ReturingCustomersLayoutContext } from '../ReturningCustomersLayout';
import TreatmentSelection from './TreatmentSelection';
import RadioSelect from '../../../../components/RadioSelect/RadioSelect';
import { MainTracker } from '../../../../PixelTrackers/MainTracker';
import ProductAvailabilityTag from '../../FreeDosageChooseTreatment/components/ProductAvailabilityTag';
import { formatNumberByLocale } from '../../../../utils/translator';
import * as translator from "../../../../utils/translator"


interface ProductCardProps {
  productCatalogID: number,
  title: string,
}

const ProductCard: React.FC<ProductCardProps> = ({ productCatalogID, title }) => {
  const cardRef = useRef<HTMLDivElement>(null);

  const context = useContext(ReturingCustomersLayoutContext);
  const groupedProducts = context?.groupedProducts;
  const isSelected = useMemo(() => context?.selectedCatalogID === productCatalogID, [context?.selectedCatalogID]);
  const isFrOrAllowPrescription = context?.isFR || context?.allowPrescriptionOnlyFlow;
  const isOutOfStock = useMemo(() => (groupedProducts && groupedProducts[productCatalogID].every((product) => product.items.every((item) => !item.in_stock))), [groupedProducts, productCatalogID]);
  const disabled = isOutOfStock && !isFrOrAllowPrescription;
  const flattenedProductItems = useMemo(() => groupedProducts && groupedProducts[productCatalogID].flatMap((product) => product.items), [groupedProducts, productCatalogID]);

  const startingPrice = useMemo(() => {

    if (context?.countryID.toUpperCase() == "FR"){
      return Math.min(...flattenedProductItems?.filter((productItem) => productItem.in_stock).map((productItem) => productItem.price) || []) || 0

    } else {
      return Math.min(...flattenedProductItems?.map((productItem) => productItem.price) || []) || 0
    }

  },[flattenedProductItems])
  const productItemToPlan = context?.productItemToPlan;

  const onSelect = useCallback(() => {
    if (groupedProducts && context?.selectedCatalogID !== productCatalogID) {
      context?.setSelectedCatalogID(productCatalogID);
      const firstInStockProduct = context?.findByDefaultDosage(groupedProducts[productCatalogID]) || groupedProducts[productCatalogID].find((product) => product.in_stock || context?.allowPrescriptionOnlyFlow);
      MainTracker.superProperties({ "Product Name": firstInStockProduct!.title });
      MainTracker.track("click", "Click change Product Name");
      context?.setSelectedProductID(firstInStockProduct!.product_id);
      const firstInStockProductItem = context?.findQuantityBasedOnRule(firstInStockProduct!) || firstInStockProduct!.items.find((item) => item.in_stock || context?.allowPrescriptionOnlyFlow);
      context?.setSelectedProductItemID(firstInStockProductItem!.product_item_id);
      context?.setIsNoPreferenceSelected(false);
      if (productItemToPlan) {
        const plans = productItemToPlan[firstInStockProductItem!.product_item_id];
        if (plans) {
          context?.setSelectedSubscriptionPlanID(plans[0]);
        }
      }
    }
    // scrollToTop();
  }, [groupedProducts, context, productCatalogID, productItemToPlan]);

  // ---- Not sure we even want this, its nice on mobile, but weird on desktop :/ -----
  // const scrollToTop = () => {
  //   setTimeout(() => {
  //     if (cardRef.current) {
  //       cardRef.current.scrollIntoView({ behavior: 'smooth', block: "start" });
  //     }
  //   }, 200)
  // }

  const bgColor = useMemo(() => {
    if (isSelected) {
      return "bg-[#e7fcf7]"
    } else if (disabled) {
      return "bg-[#f0eded]"
    }
    return "bg-white"
  }, [disabled, isSelected])

  return (
    <div ref={cardRef} id={`catalog_${productCatalogID}`} onClick={disabled ? () => { } : onSelect} style={{ border: isSelected ? "1px solid #11ddac" : "" }} className={`${bgColor} scroll-mt-[20px] flex flex-col items-center rounded-md ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}>
      <div className={`flex px-4 select-none gap-2 flex-1 w-full py-5 `}>
        <div className='flex w-[90%] items-center '>
          <RadioSelect size={20} selected={isSelected} disabled={false} />
          <p className='text-[#0d2c54] text-sm font-medium'>{title}</p>
        </div>

        {
          context?.hidePrices || (context?.countryID.toUpperCase() == "FR" && isOutOfStock) ?
          null
          :
          <div className={`from-price ${isSelected ? "text-[#0d2c54]" : "text-[#808080]"} text-sm font-normal text-nowrap flex gap-1 items-center`}>
            <p className='text-xs font-normal'>
            {translator.translate(
                context?.languageID,
                "default",
                "ChooseTreatment/from"
            )}
            </p> {formatNumberByLocale(startingPrice, context?.localeID)} {context?.currencySign}
          </div> 
        }

        {
          context?.isFR ? <ProductAvailabilityTag inStock={!isOutOfStock} /> : null
        }

      </div>

      <div id='hidden-content' className='md:hidden w-full grow-anim'>
        {
          isSelected && <TreatmentSelection isResponsiveLayout />
        }
      </div>
    </div>
  )
}

export default ProductCard
