import React, { useContext, useMemo } from 'react'
import ProductQuantityControl from './ProductQuantityControl'
import { ChooseTreatmentContext, getMaxPricePerQuantity, getMinPricePerProduct, getMinPricePerQuantity } from '../FreeDosageLayout'
import ProductAvailabilityTag from './ProductAvailabilityTag';
import { sorted } from '../../../../utils/helpers';

interface ProductInfoCardProps {
  inStock: boolean
}

const ProductInfoCard: React.FC<ProductInfoCardProps> = ({inStock}) => {


  const chooseTreatmentContext = useContext(ChooseTreatmentContext);
  const selectedProduct = chooseTreatmentContext?.products.find((product) => product.product_id === chooseTreatmentContext.selectedProductID);
  const additionalDataObj = selectedProduct?.additional_data ? JSON.parse(selectedProduct?.additional_data) : {};

  const productsToShow = useMemo(() => {
    let filteredProductsForShow = []
    if (inStock) {
      filteredProductsForShow = selectedProduct?.items.filter((item) => item.in_stock) || [];
    } else {
      filteredProductsForShow = selectedProduct?.items || [];
    }

    return sorted(filteredProductsForShow, (a, b) => a.quantity - b.quantity)
  }, [inStock, selectedProduct])

  let customDosageForCannabis = ''
  let strain = ''
  let shouldUseCannabisDosage = false;

  if (additionalDataObj) {
    if (additionalDataObj['THC'] && additionalDataObj['CBD'] && additionalDataObj['strain']) {
      shouldUseCannabisDosage = true;
      customDosageForCannabis = `THC: ${additionalDataObj['THC']} | CBD: ${additionalDataObj['CBD']}`;
      strain = `Sorte: ${additionalDataObj['strain']}`
    }
  }

  return (
    <div id='product_info' className='rounded-lg bg-white'>
      {/* upper section (green) */}
      <div className='px-4 py-8 rounded-lg border-2 rounded-br-3xl bg-[#E7FCF7] border-[#E7FCF7] flex justify-around gap-4'>
        <div className='flex flex-col ml-2'>
          <p className='text-xl font-bold text-[#0D2C54]'>{selectedProduct?.title}</p>
          {
            shouldUseCannabisDosage ? <div className='mt-4'>
              <p className='text-sm text-[#0A9281] font-medium'>{customDosageForCannabis}</p>
              <p className='text-sm text-[#0A9281] font-medium'>{strain}</p>
            </div> : null
          }
        </div>

        <div className='flex flex-col gap-5 items-center'>
          {/* price and tags */}
          {
            inStock ? 
            <div id='price_and_tags' className='p-2 flex flex-col justify-center items-center'>
                    <div className='flex gap-1 '>
                    {selectedProduct?.quantity_type == 'gram' ?
                    <>
                      <p className='text-sm text-nowrap font-medium text-[#0D2C54]'>{getMinPricePerQuantity(selectedProduct!)}</p>
                      <p className='text-sm text-nowrap font-medium text-[#0D2C54]'>-</p>
                      <p className='text-sm text-nowrap font-medium text-[#0D2C54]'>{getMaxPricePerQuantity(selectedProduct!)} €</p>
                      <p className='text-sm text-green-500'>*</p>
                    </>
                    :
                    <>
                      <p className='text-sm text-nowrap font-medium text-[#0D2C54]'>ab {getMinPricePerProduct(selectedProduct!)} €</p>
                    </>
                    }
                    </div>
                    {selectedProduct?.quantity_type == 'gram' &&
                      <p className='text-xs whitespace-nowrap text-[#556D8C]'>pro Gramm</p>
                    }
            </div> : null
          }
          <ProductAvailabilityTag inStock={inStock} />
        </div>
      </div>

      {/* lower section (white) */}
      {
        selectedProduct?.items ?
          <ProductQuantityControl items={productsToShow} /> // If premium then filter out-of-stock items
          : null
      }
    </div>
  )
}

export default ProductInfoCard
