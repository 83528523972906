import React, { useEffect } from 'react'
import {useParams,useOutletContext} from "react-router-dom";
import MyAccountContentHeader from '../MyAccountContentHeader/MyAccountContentHeader';
import MyAccountSubscriptionOrderCard from '../MyAccountSubscriptionOrderCard/MyAccountSubscriptionOrderCard';
import * as translator from '../../../../utils/translator';

interface MyAccountSubscriptionsProps {
  showOneCardWithEditPanel:boolean;
}

// {translator.translate(language, "my_account", 'MyOrders/title')}

const MyAccountSubscriptions:React.FC<MyAccountSubscriptionsProps> = ({showOneCardWithEditPanel}) => {
  const {setActiveMenuLinkId, myAccountData} = useOutletContext<any>();
  const {order_hash_id} = useParams<{  order_hash_id: string | undefined }>(); //the hashID from url
  const language = myAccountData.locale.language_id

  let description = translator.translate(language, "my_account", 'SubscriptionOrders/subTitle')
  if(!myAccountData.mySubscriptions.subscriptionCount){
    description = translator.translate(language, "my_account", 'SubscriptionOrders/subTitleNoSubscriptions')
  }

  useEffect(()=>{
    setActiveMenuLinkId("orders-subscription");
  },[showOneCardWithEditPanel]);

  return (
    <>
      <div className="account-content">
        <MyAccountContentHeader
          title={translator.translate(language, "my_account", 'SubscriptionOrders/title')}
          description={description}
        />

        {
          showOneCardWithEditPanel && order_hash_id ? (
          //showOneCardWithEditPanel means we Show only 1 spesific subscription by order_hash_id
            <MyAccountSubscriptionOrderCard 
              showEditPanel={showOneCardWithEditPanel}
              language={language} 
              orderHashId = {order_hash_id}
              subscriptionPrice={myAccountData.mySubscriptions.subscriptionData[order_hash_id].subscriptionPrice}
              regularPrice={myAccountData.mySubscriptions.subscriptionData[order_hash_id].regularPrice}
              currencySign={myAccountData.mySubscriptions.subscriptionData[order_hash_id].currencySign}
              orderPeriod={myAccountData.mySubscriptions.subscriptionData[order_hash_id].plan}
              deliveryAddress={myAccountData.mySubscriptions.subscriptionData[order_hash_id].address}
              nextBillingDate={myAccountData.mySubscriptions.subscriptionData[order_hash_id].next_billing}
              nextDeliveryDate={myAccountData.mySubscriptions.subscriptionData[order_hash_id].next_delivery}
              image={myAccountData.mySubscriptions.subscriptionData[order_hash_id].image}
              title={myAccountData.mySubscriptions.subscriptionData[order_hash_id].title}
          />
            )
          :
            (
          Object.values(myAccountData.mySubscriptions.subscriptionData).map((subscriptionOrder:any, index:number) => (
          <MyAccountSubscriptionOrderCard 
            showEditPanel={showOneCardWithEditPanel}
            key={index}
            language={language} 
            orderHashId={subscriptionOrder.hashID}
            subscriptionPrice={subscriptionOrder.subscriptionPrice}
            regularPrice={subscriptionOrder.regularPrice}
            currencySign={subscriptionOrder.currencySign}
            orderPeriod={subscriptionOrder.plan}
            deliveryAddress={subscriptionOrder.address}
            nextBillingDate={subscriptionOrder.next_billing}
            nextDeliveryDate={subscriptionOrder.next_delivery}
            image={subscriptionOrder.image}
            title={subscriptionOrder.title}
          /> 
          )))
        }
      </div>
    </>
  );
}

export default MyAccountSubscriptions