import "./PersonalAdderssShipping.css";
import "../../../../core.css";

import OptionCardCategory from "../OptionCardCategory/OptionCardCategory";
import OptionCard from "../OptionCard/OptionCard";
import ShippingForm from "../ShippingForm/ShippingForm";
import * as translator from "../../../../utils/translator";
import { ShippingDataContext } from "../../Shipping";
import { updatePrescriptionOnlyDBCart } from "../../../ChooseTreatmentVariants/helpers";
import { updateBonusDBCart } from "../../helpers";
import { useEffect, useContext } from "react";


const SHOW_COUPON_CONFIRMED = "show_coupon_confirmed";
const SHOW_COUPON_INPUT = "show_coupon_input";
const DISCOUNT_DATA = "discount_data";
const COUPON = "coupon"; // coupon value display in orderWindow
const OTC_COUPON = "otc_coupon"
const SET_LOADING = "set_loading"


const PersonalAdderssShipping = ({
  allowPrescriptionOnly = true,
  updatePrescriptionOnly,
  pageState,
  selected = false,
  onClick,
  params,
  updateDeliveryMethodID,
  selectedPersonalShippingIndex,
  updateSelectedPersonalShippingIndex,
  cartID,
}) => {

  const { 
    language,
    destinationCountry, 
    subscriptionDiscount,
    updateDispatch,
    prescriptionOnly,
    isAutoBonus,
    isTestKit,
    limitToPrescriptionOnly,
    catalog,
    allowEPrescriptionOnlyFeature
  } = useContext(ShippingDataContext);

  const toggleOffPrescriptionOnlyOption = async () =>{
    updateSelectedPersonalShippingIndex(0);
    updatePrescriptionOnly(false);
    //change prescriptionOnly to 0 in cart
    await updatePrescriptionOnlyDBCart(cartID, 0);
  }

  useEffect(() => {
    updateDispatch(SET_LOADING, true);

    const clearCouponBonus = async () =>{
      if (!isAutoBonus) {
        await updateBonusDBCart(null, null, null, subscriptionDiscount); //dbcall
      }
      updateDispatch(SHOW_COUPON_CONFIRMED, false);
      updateDispatch(DISCOUNT_DATA, {});
      updateDispatch(COUPON, 0);
      updateDispatch(OTC_COUPON, 0);
      updateDispatch(SHOW_COUPON_INPUT, true);
    }
    clearCouponBonus()
    if(!allowPrescriptionOnly){
      toggleOffPrescriptionOnlyOption()
      updateDispatch(SET_LOADING, false)
    }
    updateDispatch(SET_LOADING, false);

  }, [subscriptionDiscount, destinationCountry]) //was set to change when destinationCountry changes but then subscriptionDiscount state didnt update in time
  

  //by default, the selected treatment IS NOT prescription only
  useEffect(() => {
    if (!prescriptionOnly) {
      updatePrescriptionOnlyDBCart(cartID, 0);
    } else {
      updatePrescriptionOnlyDBCart(cartID, 1);
    }

    if (limitToPrescriptionOnly) {
      updatePrescriptionOnlyDBCart(cartID, 1); //dbcall
      updateSelectedPersonalShippingIndex(1);
      updatePrescriptionOnly(true);
    }
  }, [prescriptionOnly, limitToPrescriptionOnly]);

  const isOptionSelected = (key) => {
    if (key === selectedPersonalShippingIndex) {
      return true;
    }

    return false;
  };

  return pageState.serviceOptions ? (
    <div className={"delivery-panel-outer-container" + (selected ? " selected-pm" : "")}>
      <OptionCardCategory
        title={translator.translate(language, "shipping", "ShippingForm/main_title")}
        selected={selected}
        onClick={onClick}
      />

      <div className="delivery-form-personal-address ">
        <div className={"hideable-content" + (selected ? "" : " hidden")}>
          <div className="delivery-options ">
            <p className="delivery-options-title">
              {pageState.serviceOptions["service_options_title"]
                ? pageState.serviceOptions["service_options_title"].value
                : ""}
            </p>

            {
              limitToPrescriptionOnly ? null : (
              <OptionCard
                isOptionSelected={isOptionSelected(0)}
                data={{
                  value: pageState.serviceOptions["service_option_1"].value,
                  description: translator.translate(language, "shipping", "serviceOptions/service_option_1_description"),
                  bold_description: catalog.content_type === "cannabis" ? translator.translate(language, "shipping", "serviceOptions/service_option_1_cannbis_bold_description") : ""
                }}
                badgeText={translator.translate(language, "shipping", "serviceOptions/service_option_1_badge_text")}
                pageState={pageState}
                onClick={async () => {
                  if (!isOptionSelected(0)) {
                    await toggleOffPrescriptionOnlyOption()
                  }
                }
                }
                option={"service_option_1"}
              />
              )
            }

            {
              !isTestKit ? (
                <>
                  <OptionCard
                    isOptionSelected={isOptionSelected(1)}
                    data={
                      (catalog.content_type === "cannabis" && allowEPrescriptionOnlyFeature)?

                      {
                        value:  translator.translate(language, "shipping", "serviceOptions/service_option_2_cannabis_title"),
                        description: translator.translate(language, "shipping", "serviceOptions/service_option_2_cannabis_description"),
                      }

                      :

                      {
                        value: pageState.serviceOptions["service_option_2"].value,
                        description: translator.translate(language, "shipping", "serviceOptions/service_option_2_description").replace(":price",params.price),
                      }
                    }
                    pageState={pageState}
                    onClick={async () => {
                      if (!isOptionSelected(1)) {
                        updateDispatch(SET_LOADING, true);
                        updatePrescriptionOnly(true);
                        ////change prescriptionOnly to 1 in cart
                        await updatePrescriptionOnlyDBCart(cartID, 1); //dbcall
                        updateSelectedPersonalShippingIndex(1);

                        //update coupon bonus related attributes

                        if (!isAutoBonus) {
                          await updateBonusDBCart(null, null, null); //dbcall
                        }
                        updateDispatch(SHOW_COUPON_CONFIRMED, false);
                        updateDispatch(DISCOUNT_DATA, {});
                        updateDispatch(COUPON, 0);
                        updateDispatch(OTC_COUPON, 0);
                        updateDispatch(SHOW_COUPON_INPUT, true);
                        updateDispatch(SET_LOADING, false);
                      }


                    }}
                    option={"service_option_2"}
                    disabled={!allowPrescriptionOnly}
                    
                  />
                </>
              ) : null
            }

          </div>

          <p className="delivery-options-title">{translator.translate(language, "shipping", "layout/living_address")}{" "}</p>

          <ShippingForm language={language} selected={selected} />
        </div>
      </div>
    </div>
  ) : null;
};

export default PersonalAdderssShipping;
