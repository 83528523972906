import ThankYouStep from "../ThankYouStep/ThankYouStep";

const ThankyouStepsWidget = ({ isEPrescriptionOnly = false ,language = 'de', page = 'thankyou', isOrderOnlyPrescription = false, isERecipe = false, isSelfPickUp = false }) => {
    return (
        
        <div className="thankyou-steps">
            <ThankYouStep isEPrescriptionOnly={isEPrescriptionOnly} language={language} page={page} stepNumber={1} />
            <ThankYouStep isEPrescriptionOnly={isEPrescriptionOnly} language={language} page={page} stepNumber={2} />
            <ThankYouStep isSelfPickUp={isSelfPickUp} isEPrescriptionOnly={isEPrescriptionOnly} language={language} page={page} stepNumber={3} />

            {page === 'wire' && <ThankYouStep isEPrescriptionOnly={isEPrescriptionOnly} language={language} page={page} stepNumber={4} />}
        </div>
    );
};

export default ThankyouStepsWidget;
