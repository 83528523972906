import React, { useEffect } from "react";
import { useParams, useOutletContext, Link } from 'react-router-dom';
import "./MyAccountOrderTreatmentInstructions.css"
import * as translator from "../../../../utils/translator"
import MyAccountContentHeader from "../MyAccountContentHeader/MyAccountContentHeader";

const MyAccountOrderTreatmentInstructions = () => {
  const {setError, myAccountData, country} = useOutletContext<any>();
  const { order_hash_id } = useParams();
  const language = myAccountData.locale.language_id
  
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  
  if(!order_hash_id || !country){
    return setError(400)
  }else if(!myAccountData.myOrders.ordersData[order_hash_id]){
    return setError(400)
  }
  const orderData = myAccountData.myOrders.ordersData[order_hash_id]
  let instructionsTitle = translator.translate(language, "my_account", 'MyOrders/instructionsTitle')
  instructionsTitle = instructionsTitle.replace(':shortTitle', orderData.shortTitle)

  return (
  <>
  <div className="account-content">
    <MyAccountContentHeader
          title={translator.translate(language, "my_account", 'MyOrders/cardInstructions')}
          description={""}
    />

    <div className="orders-list">
      <div className="orders-item__image"><img className="item__image" src={orderData.image}></img></div>
      <div className="orders-item__description">
        <div className="description-wrapper">
          <h6 className="orders-name">{orderData.title}</h6>
          <div className="account-order-card-links">
            <Link to={`/${country}/account/my-orders/${order_hash_id}/prescription`} className="account-order-card-link">{translator.translate(language, "my_account", 'MyOrders/cardQuestionnaire')}</Link>
            <Link to={`/${country}/account/my-orders/${order_hash_id}/details`} className="account-order-card-link">{translator.translate(language, "my_account", 'MyOrders/cardDetails')}</Link>
          </div>
        </div>
      </div>
      <div className="orders-item__info">
        <div className="orders-info">
          <div className="orders-info__col">
            <div className="orders-info__title">{translator.translate(language, "my_account", 'MyOrders/cardHashID')}</div>
            <div className="orders-info__item">{orderData.hashID}</div>
          </div>
          <div className="orders-info__col">
            <div className="orders-info__title">{translator.translate(language, "my_account", 'MyOrders/cardDateTime')}</div>
            <div className="orders-info__item">{orderData.orderDate}</div>
          </div>
          <div className="orders-info__col">
            <div className="orders-info__title">{translator.translate(language, "my_account", 'MyOrders/cardStatus')}</div>
            <div className="orders-info__item">{translator.translate(language, "my_account", `MyOrders/status/${orderData.status}`)}</div>
          </div>
        </div>
      </div>

    </div>
    <div className="treatment-instructions__wrap">
      <p className="treatment-instructions__title">{instructionsTitle}</p>
      <p className="treatment-instructions__description">{orderData.applicationNotes}</p>
    </div>
  </div>

  </>
  )
}

export default MyAccountOrderTreatmentInstructions