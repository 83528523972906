
import React, { useContext } from 'react'
import * as translator from "../../../../utils/translator"
import { RouterCartContext } from '../../ChooseTreatmentRouter'

interface ISubmitButtonProps {
    onClick: () => void,
    desktop?: boolean
    disabled?: boolean,
    languageID: string,
}

const SubmitButton: React.FC<ISubmitButtonProps> = ({onClick, desktop, disabled,languageID}) => {

  const routerCartContext:any = useContext(RouterCartContext)
  const cart = routerCartContext.cartData
  const isSelfPickup = cart?.self_pickup_pharmacy_id ? true : false


  const styleBasedOnDisabled = () => {
    if (disabled) {
      return "cursor-not-allowed rounded-md bg-[rgba(17,221,172,.8)] opacity-60  p-5 text-white text-center font-bold "
    }
    return `cursor-pointer rounded-md bg-[#11DDAC] p-5 text-white text-center font-bold hover:opacity-90 transition-opacity`
  }

  return (
    <div id={desktop ? "desktop_submit_btn" : "submit_btn"} onClick={disabled ? () => {} : onClick} className={styleBasedOnDisabled()}>
        <p id='submit-button-text'>
          {`
            ${translator.translate(languageID,"default", `DrugSelectionSidePanel/${isSelfPickup ? "button-text-self-pickup" : "button-text"}`)} 
            ${disabled ? "..." : ""} 
          `}
        </p>
    </div>
  )
}

export default SubmitButton
