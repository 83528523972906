let uiEvents = {steps: []} as any
let httpEvents = {steps:[]} as any

const saveFile = async (dataToSave:any,filename:string) => {

    const blob = new Blob([JSON.stringify(dataToSave, null, 2)], {type : 'application/json'});

    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

const getSelectors = (eventType:string, element:any) =>{
   // find id of element or parent in case of clicks
   let elementID:any
   let currElement = element
   let level = 0
   do{
 
     if (!currElement){
       return
     }
     elementID = currElement.id
     level++
     currElement = currElement.parentElement
   } while (!elementID && level < 3 && eventType === 'click')
 
   // ignore top level clicks
   if (elementID === "root"){
     return
   }

   if (!elementID){
    let elementText = element.innerText?.trim() as string
    // try to use element text
    if (elementText && elementText.length < 60){
      return [[`text/${element.innerText.trim()}`]]
    }
    else{
      let compoundSelector = buildCompoundSelector(element)
      return [[compoundSelector]]
    }
   }
   else{
      return [[`#${elementID}`]]
   }
}

function buildCompoundSelector(element:any){
  if (element.tagName === "BODY") return "BODY";
  const names = [];
  while (element.parentElement && element.tagName !== "BODY") {
      if (element.id) {
          names.unshift("#" + element.getAttribute("id")); // getAttribute, because `elm.id` could also return a child element with name "id"
          break; // Because ID should be unique, no more is needed. Remove the break, if you always want a full path.
      } else {
          let c = 1, e = element;
          for (; e.previousElementSibling; e = e.previousElementSibling, c++) ;
          names.unshift(element.tagName + ":nth-child(" + c + ")");
      }
      element = element.parentElement;
  }
  return names.join(">");
}

const buildStore = () =>{
    let store = {} as any
    let eventsTrackDataStore = window.localStorage.getItem('eventsTrackDataStore') as any
    if (eventsTrackDataStore){
      eventsTrackDataStore = JSON.parse(eventsTrackDataStore)
    }
    store.customerID = window.localStorage.getItem('eventsTrackCID')
    store.token = window.localStorage.getItem('eventsTrackToken')

    let eventsTrackDataStoreVolatile = window.sessionStorage.getItem('eventsTrackDataStoreVolatile') as any
    if (eventsTrackDataStoreVolatile){
      eventsTrackDataStoreVolatile = JSON.parse(eventsTrackDataStoreVolatile)
    }

    store.last_shipped_cart = window.sessionStorage.getItem('last_shipped_cart')
    store.last_thankyou_order = window.sessionStorage.getItem('last_thankyou_order')

    return {...store,...eventsTrackDataStoreVolatile,...eventsTrackDataStore}
}

// prevent duplicate events
let lastClicked = new Date().getTime()
const handleEvent = (event:any) => {

  if (event.target.id === "export-rec-button"){
    saveFile(uiEvents,'events.browser.json')
    saveFile(httpEvents,'events.http.json')
    saveFile(buildStore(),'data.store.json')

    return
  }

  
  let selectors = getSelectors(event.type,event.target)

  if (event.type === "click"){

    let now = new Date().getTime()
    // check that at least elapsed 10 milli seconds, prevents duplicates click events when bubble up
    if (now < lastClicked + 10){
      return 
    }
    lastClicked = new Date().getTime()

    uiEvents.steps.push({type:event.type,selectors})
    
  }
  else if (event.type === "change" && event.target.value && event.target.id){
    // check if element is visible
    if (event.target.offsetParent){
      uiEvents.steps.push({type:event.type,value:event.target.value,selectors})
    }
  }
  else if (event.type === "input" && event.target.value && event.target.id){
    // check if element is visible
    if (event.target.offsetParent){
      uiEvents.steps.push({type:event.type,value:event.target.value,selectors})
    }
  }
  else if (event.type === "keydown" && event.key === "Enter"){
    //store enter events
    uiEvents.steps.push({type:"keyDown",key: event.key,selectors})
  }
}

let url = ''
const setUiHooks = () =>{

  url = window.location.href
  // always save first navigation
  uiEvents.steps.push({type:"navigate",url:window.location.href})

  document.addEventListener('click', handleEvent,true);
  //document.addEventListener('input', handleEvent,true);
  document.addEventListener('change', handleEvent,true);
  document.addEventListener('keydown', handleEvent,true);

  setInterval(() => {
    if (url != window.location.href) {
      url = window.location.href

      uiEvents.steps.push({type:"navigate",url:window.location.href})

    }
  }, 50);
}

let initialized = false
const initIfNeeded = ()=>{
  if (initialized){
    return 
  }
  initialized = true
  const div = document.createElement('div');
  div.id = "export-rec-button"
  div.style.boxSizing = "border-box";
  div.className = "sumbitButton-container questionnaire-submitButton"

  div.innerText = 'Export Test Recordings'

  document.body.appendChild(div)
  setUiHooks()
}

export const addHttpEvent = (event:any) =>{
    initIfNeeded()
    httpEvents.steps.push(event)
}