import React,{useState} from "react";
import useForm from "../../customHooks/useForm";
import InputField from "../CredentialsInput/InputField";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useCustomerContext } from "../../reducers/CustomerReducer";
import * as translator from "../../utils/translator";
import * as http from "../../utils/http";

// TODO: USE THIS COMPONENT IN QUESTIONNAIRE LOGINQUESTION TOO

interface LoginFormProps {
  language: string;
  className?: string; //"questionnaire-loginquestion-inputs-container" 
  submitButtonPlaceholder:string 
  setStickyNotificationDetails:any
}

const LoginForm: React.FC<LoginFormProps> = ({
  language,
  className,
  submitButtonPlaceholder,
  setStickyNotificationDetails,
}) => {

 
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const { customerDispatch } = useCustomerContext();
  const loginFormAttributes = useForm(
    { email: "", loginPassword: "" },
    language
  );

  const onSubmit = (e:any) => {
    e.preventDefault();
    setSubmitButtonLoading(true);

    if (loginFormAttributes.validateForm()) {
       http
        .postToServer(`login`, loginFormAttributes.form)
        .then((response) => {

          if (response.data.status === "ok") {
            customerDispatch({
              type: "logged_in",
              customerID: response.data.customerID,
              uuid: response.data.uuid,
              rememberToken: response.data.rememberToken,
              email: loginFormAttributes.form.email,
              optForSendables: response.data.optForSendables
            });
          } else {
            setStickyNotificationDetails((currentDetails:any) => ({
              show: true,
              title: "Oops",//TODO:Translations
              content: translator.translate(language, "default", "StickyNotification/login_failed"),
              level : "error",
              showInfoIcon:true,
              refresh: !currentDetails.refresh
            }))
          }
          
        })
        .catch((error) => {
          setStickyNotificationDetails((currentDetails:any) => ({
            show: true,
            title: "Oops",//TODO:Translations
            content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
            level : "error",
            showInfoIcon:false,
            refresh: !currentDetails.refresh
          }))
        })
        .finally(()=>{
            setSubmitButtonLoading(false);
        })
    } else {
      setSubmitButtonLoading(false)
    }

  };
  return (
    <>
      <form onSubmit={onSubmit} className={className}>
        <InputField
          value={loginFormAttributes.form.email}
          errorMsg={loginFormAttributes.errorMessagePerField.email}
          onChange={(value:string) => {
            loginFormAttributes.updateForm("email", value);
          }}
          type="email"
          placeholder={"E-Mail"}
          language={language}
          mandatory
          onBlur={() => loginFormAttributes.onFieldBlur("email")}
          touched={loginFormAttributes.touched.email}
        />
        
        <InputField
          value={loginFormAttributes.form.loginPassword}
          errorMsg={loginFormAttributes.errorMessagePerField.loginPassword}
          onChange={(value:string) => {
            loginFormAttributes.updateForm("loginPassword", value);
          }}
          type="password"
          placeholder={translator.translate(
            language,
            "default",
            "LoginQuestion/password"
          )}
          language={language}
          mandatory
          onBlur={() => loginFormAttributes.onFieldBlur("loginPassword")}
          touched={loginFormAttributes.touched.loginPassword}
        />
        <input type="submit" hidden />
      </form>

      <SubmitButton
        id={"login-form-submit"}
        language={language}
        loading={submitButtonLoading}
        onSubmit={onSubmit}
        width={"100%"}
        placeholder={submitButtonPlaceholder}
      />
    </>
  );
};

export default LoginForm;
