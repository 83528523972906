import { useReducer, createContext, useState, useContext } from "react";
import PagesRouter from "./router/PagesRouter"
import {CustomerContext,CustomerReducer,loadCustomerFromSession} from './reducers/CustomerReducer'
import {CartContext,CartReducer,loadCart} from './reducers/CartOrderReducer'
import Cookiebot from "./components/Cookiebot/Cookiebot";
import UnderMaintenance from "./pages/UnderMaintenance/UnderMaintenance";
import { clientConfig } from "./config";
import BonusBanner from "./components/BonusBanner/BonusBanner";

export const LocationContext = createContext();

export function useLocationContext() {
  return useContext(LocationContext);
}

function App() {

  const [customerState, customerDispatch ] = useReducer(CustomerReducer, loadCustomerFromSession());
  const [location, setLocation] = useState(null)

  const locationProviderState = {
    location,
    setLocation
  }
  
  const customerProviderState = {
    customerState,
    customerDispatch
  }

  const [cartState, cartDispatch ] = useReducer(CartReducer, loadCart());
  const cartProviderState = {
    cartState,
    cartDispatch
  }

  if(clientConfig.IS_UNDER_MAINTENANCE === "true"){
    return <UnderMaintenance/>

  } else {
    return (    
      <span>
        <CustomerContext.Provider value={customerProviderState}>
        <CartContext.Provider value={cartProviderState}>
        <LocationContext.Provider value={locationProviderState}>
        <BonusBanner/>
        <PagesRouter>
        </PagesRouter>
        <Cookiebot/>
        </LocationContext.Provider>
        </CartContext.Provider>
        </CustomerContext.Provider>
        </span>
    );
  }


}

export default App;
