import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import vCheck from "../../../../icons/vCheck.svg";
import * as http from '../../../../utils/http'
import * as translator from "../../../../utils/translator";
import { useCartContext } from "../../../../reducers/CartOrderReducer";
import "./CouponWidget.css";
import LoadingModal from "../../../../components/Modals/LoadingModal/LoadingModal";
import { MainTracker } from "../../../../PixelTrackers/MainTracker";
import { ShippingDataContext } from "../../../../pages/Shipping/Shipping";


const SHOW_COUPON_CONFIRMED =  "show_coupon_confirmed";
const SHOW_COUPON_INPUT = "show_coupon_input";
const DISCOUNT_DATA = "discount_data";
const COUPON = 'coupon'
const OTC_COUPON = 'otc_coupon'


const CouponWidget = ({ orderTotal, showCouponConfirmed , showCouponInput, discountData, updateDispatch,treatmentFee,couponDiscount}:
  { orderTotal:number, showCouponConfirmed:boolean , showCouponInput:boolean, discountData:any, updateDispatch:any,treatmentFee:number,couponDiscount:number}) => {

  const { cartDispatch } = useCartContext();
  const { country, language } = useParams();
  const [errorMsg, setErrorMsg] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [loading,setLoading] = useState(false);

  const shippingData = useContext(ShippingDataContext);

  const updateBonusDBCart = async (bonusID:number, bonusValue:any, autoBonus:boolean, couponInputCode:string, discountObj:any) => {
    try {
      await http.postToServer("cart/update_coupon_data", {
        bonusID,
        bonusValue,
        autoBonus,
        couponInputCode,
        discountObj
      },[http.MIDDLEWARES.CART_ORDER]);

    } catch (err) {
      window.clientLogger.error("Error Updating cart",err)
    }
  };

  
  const onSubmit = (couponInputCode:string) => {
    setLoading(true);
    const dataToSend = {
      coupon_code : couponInputCode,
      destinationCountry: shippingData.destinationCountry,
      currencyRates: shippingData.currencyRates,
      selectedOtcs:  shippingData.OTCAddonsAttributes.getSelectedAddons(),
      isCannabisIDAddedToOrder: shippingData.cannabisIDState.isCannabisIDAddedToOrder,
      isOnlyPrescription: shippingData.isOnlyPrescription
    }

    http.postToServer(
      `shipping/${country}/${language}/coupon`,
      dataToSend,
      [http.MIDDLEWARES.CART_ORDER,http.MIDDLEWARES.CUSTOMER_ID]
      )
      .then(
      (response) => {
        setLoading(false);
        if(response.data.status === "ok"){
          cartDispatch({
            //store the discount in session
            type: "store",
            cart: {
              coupon_discount:response.data.discount.bonus_value
            },
          });

          const discountObj = response.data.discount;
          const discountValue = discountObj.bonus_value;

          const discountValueInEUR = discountValue / (shippingData.currencyRates[shippingData.destinationCountry].rate);

          MainTracker.superProperties({
            "Coupon Amount": discountValue,
            "Coupon Amount EUR": discountValueInEUR,
            "Coupon ID": discountObj.bonus_id,
            "Coupon Name": discountObj.bonus_name,
            "Coupon Code": couponInputCode
          })
          MainTracker.track('click','Apply Coupon');

          discountObj.bonus_value = discountValueInEUR

          updateBonusDBCart(discountObj.bonus_id, discountObj.bonus_value, false, couponInputCode, discountObj);
          if(discountObj.types_to_values?.['otc']){
            updateDispatch(OTC_COUPON, discountObj.types_to_values['otc'])
            updateDispatch(COUPON, discountValue - discountObj.types_to_values['otc'])
          }else{
            updateDispatch(COUPON, discountValue)
          }

          updateDispatch(SHOW_COUPON_CONFIRMED, true);
          updateDispatch(SHOW_COUPON_INPUT ,false);
          setErrorMsg("");
          updateDispatch(DISCOUNT_DATA,discountObj);
        } else {
          MainTracker.track('click','Apply Coupon Failed',{"Coupon Code": couponInputCode, "Coupon Error":response.data.msg});
          let errorMessage = translator.translate(language,"shipping",`CouponWidget/${response.data.msg}`) ?? response.data.msg; 
          setErrorMsg(errorMessage);

        }
      })
      .catch((error) => {
        setLoading(false);
        window.clientLogger.error("CouponWidget error post to server", error);
        setErrorMsg("Network Error");
      });
     
  };

  
  const deeplinkCoupon = sessionStorage.getItem('cpn');
  useEffect(() => {
    if (deeplinkCoupon) {
      onSubmit(deeplinkCoupon)
    }
  }, [])
  


  const getCurrencySignByCountryCode = (countryCode:string) => {
    if(countryCode === "ch"){
      return " CHF";
    }else {
      return " €";
    }
  }

  return (
    <>
    {loading && <LoadingModal/>}

    <div className="coupon-container">
      <p className="orderWindow-container-title">{translator.translate(language,"shipping","CouponWidget/title")}</p>

      {showCouponConfirmed && (couponDiscount || shippingData.otcCouponDiscount) && (
        <>
          <div className="coupon-field-confirmed">
            <p>-{(couponDiscount+shippingData.otcCouponDiscount).toFixed(2)} {getCurrencySignByCountryCode(shippingData.destinationCountry)}</p>
            <img className="coupon-confirmed-vCheck" src={vCheck} alt="check" />
          </div>

          <p
            onClick={() => updateDispatch(SHOW_COUPON_INPUT,!showCouponInput)}
            className="coupon-confirmed-change-coupon"
          >
            {translator.translate(language,"shipping","CouponWidget/change_coupon")}
          </p>
        </>
      )}

      {showCouponInput && 
        <div
          className={errorMsg === "" ? "coupon-field" : "coupon-field-error"}
        >
          <input
            onChange={(e) => setInputValue(e.target.value)}
            id="coupon-input"
            className="coupon-input"
            type={"text"}
            placeholder= {translator.translate(language,"shipping","CouponWidget/coupon_code_placeholder")}
          />
          <button
            disabled={inputValue === ""}
            onClick={() => onSubmit(inputValue)}
            id="coupon-button"
            className="coupon-button"
          >
            <p>{translator.translate(language,"shipping","CouponWidget/coupon_activate")}</p>
          </button>
        </div>
      }

      {errorMsg !== "" && showCouponInput && (
        <span className="coupon-error-message"> {errorMsg} </span>
      )}
    </div>
    </>
  );
};

export default CouponWidget;