import React,{useState,useEffect} from "react";
import {useOutletContext, useNavigate} from "react-router-dom";
import BinarySelect from "../../../../components/BinarySelect/BinarySelect";
import InputField from "../../../../components/CredentialsInput/InputField";
import InfoCell from "../../../../components/InfoCell/InfoCell";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import useForm from "../../../../customHooks/useForm";
import Significance from "../../../Questionnaire/components/Significance/Significance";
import MyAccountContentHeader from "../MyAccountContentHeader/MyAccountContentHeader";
import * as translator from '../../../../utils/translator';
import * as http from "../../../../utils/http";
import "./MyAccountPersonalData.css"


const MyAccountPersonalData = () => {
  const {setActiveMenuLinkId, setStickyNotificationDetails ,myAccountData,country,language} = useOutletContext<any>();
  const [deactivateAccount, setDeactivateAccount] = useState(false)
  const navigate = useNavigate()

  const emailField = useForm({
    email: myAccountData.personalDetails.email
  },language)

  const onSubmit = () =>{
    http.postToServer(`confirmation-email`,
    { 
      email: myAccountData.customer.email, 
      newEmail: emailField.form["email"],
      country, 
      language, 
      emailType:'changeEmail' 
    })
    .then((response) => {
      if(response.data == 'email taken'){
        setStickyNotificationDetails({
          show: true,
          title: translator.translate(language, "my_account", `stickyNotifications/error/errorTitle`),
          content: translator.translate(language, "my_account", `stickyNotifications/error/emailTaken`),
          level : "error",
          showInfoIcon:true,
        })
      }else{
        setStickyNotificationDetails({
          show: true,
          title: translator.translate(language, "my_account", `stickyNotifications/success/changeEmailTitle`),
          content: translator.translate(language, "my_account", `stickyNotifications/success/changeEmail`),
          level : "info",
          showInfoIcon:true,
        })
      }
        })
      .catch(
        setStickyNotificationDetails({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
          level : "error",
          showInfoIcon:false,
        })
      )
  }

  useEffect(() => {
    setActiveMenuLinkId("personal-data");
    if(deactivateAccount){
      if(myAccountData.mySubscriptions?.subscriptionCount > 0){ // the ? because FR doesn't have mySubscriptions
        navigate(`/${country}/account/orders-subscription`);
        setStickyNotificationDetails({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/error/deactivateAccount`),
          level : "error",
          showInfoIcon:false,
        })
        window.scrollTo(0, 0);

      }else{
        http.postToServer(`confirmation-email`, { email: myAccountData.customer.email, country, language, emailType:'deactivateAccount' })
        .then((response) => {
              setStickyNotificationDetails({
                show: true,
                title: translator.translate(language, "my_account", `stickyNotifications/success/deactivateAccountTitle`),
                content: translator.translate(language, "my_account", `stickyNotifications/success/deactivateAccount`),
                level : "error",
                showInfoIcon:true,
              })
            })
          .catch(
            setStickyNotificationDetails({
              show: true,
              title: "",
              content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
              level : "error",
              showInfoIcon:false,
            })
          )
      }
    }
  },[deactivateAccount]);

  return(
    <>
      <div className="account-content">
        <MyAccountContentHeader
          title={translator.translate(language, "my_account", `PersonalData/title`)}
          description={translator.translate(language, "my_account", `PersonalData/subTitle`)}
          />
       
        <div className="personal-data-form-container">
          <Significance 
            id={"account-significance"}
            dangerSignificancesRefsDispatch={{}}
            parentID={null}
            showIcon 
            level={"warning"} 
            fontWeight={400}
            text={translator.translate(language, "my_account", `PersonalData/note`)}/>

          <div className="personal-data-form">
            <InputField
            mandatory={true}
            id="email-field"
            placeholder={translator.translate(language, "my_account", `forms/email`)}
            value={emailField.form["email"]}
            errorMsg={emailField.errorMessagePerField["email"]}
            language={language}
            onBlur={() => emailField.onFieldBlur("email")}
            touched={emailField.touched.email}
            onChange={(value: any) => {
              emailField.updateForm("email", value);
            }}
            />

            <div className="info-cells-row">
              <span className="info-cell-title">{translator.translate(language, "my_account", `PersonalData/birthday`)}</span> 
                <InfoCell title={""} value={myAccountData.personalDetails.birthday.day} />
                <InfoCell title={""} value={myAccountData.personalDetails.birthday.month} />
                <InfoCell title={""} value={myAccountData.personalDetails.birthday.year} />
            </div>

            <div className="info-cells-row-2">
              <span className="info-cell-title"> {translator.translate(language, "my_account", `PersonalData/gender`)} </span>
                <BinarySelect
                  locked
                  valueLeft={translator.translate(language, "my_account", `PersonalData/man`)}
                  valueRight={translator.translate(language, "my_account", `PersonalData/woman`)}
                  valuePicked={
                    myAccountData.personalDetails.gender === 1 ?
                    translator.translate(language, "my_account", `PersonalData/man`)
                    :
                    translator.translate(language, "my_account", `PersonalData/woman`)
                  }
                />
            </div>

            <div className="info-cells-row-3">
                <InfoCell title={translator.translate(language, "my_account", `PersonalData/firstName`)} value={myAccountData.personalDetails.firstName} />
                <InfoCell title={translator.translate(language, "my_account", `PersonalData/lastName`)} value={myAccountData.personalDetails.lastName} />
            </div>

            <div className="delivery-address-last-row">
              <span className="deactivate-account" onClick={()=>{setDeactivateAccount(true)}}>
                {translator.translate(language, "my_account", `PersonalData/deactivate`)}
              </span>
              <SubmitButton 
                onSubmit={onSubmit}
                id={"delivery-address-submit"}
                placeholder={translator.translate(language, "my_account", `DeliveryAddress/submit`)} 
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default MyAccountPersonalData;
