import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import MyAccountContentHeader from "../MyAccountContentHeader/MyAccountContentHeader";
import Conversation from "../Conversation/Conversation";
import * as translator from "../../../../utils/translator";
import HistoricalConverstaionsHeader from "../HistoricalConverstaionsHeader";
import Select from "../../../../components/Select/Select";
import Disclaimer from "../../../ChooseTreatmentVariants/components/Disclaimers/Disclaimer"


type ConversationMention = {
  mention_type: string;
  mention_id: number;
  mention_text: string;
};

type AskADoctorOption = {
  id: number;
  value: string;
  option_name: string;
  [languageCode: string]: string | number;
}

type AskADoctorOptions = {
  id: number;
  option_name: string;
  enabled: number;
  type: 'regular' | 'warning' | 'popup';
  created_at: string;
  updated_at: string;
  [languageCode: string]: string | number;
};



const MyAccountAskDoctor = () => {
  const { setActiveMenuLinkId, myAccountData, language } = useOutletContext<any>();
  const [shownHistoricalConversationIndex, setShownHistoricalConversationIndex] = useState<number | null>(null);
  const [selectedOption, setSelectedOption] = useState<AskADoctorOption | null>(null);
  const askADoctorOptionsArray: AskADoctorOptions[] = myAccountData.askADoctor.askADoctorOptions;
  const askADoctorOptionsNames = askADoctorOptionsArray.map(option => ({
    id: option.id,
    value: option[language],
    option_name: option.option_name
  }));

  const warningOptions = askADoctorOptionsArray.filter(option => option.type === 'warning').map(option => option.id);
  const popupOptions = askADoctorOptionsArray.filter(option => option.type === 'popup').map(option => option.id);

  const activeConversation = myAccountData.askADoctor.conversations.active;
  const historicalsConversations = myAccountData.askADoctor.conversations.historicals;

  const showSelectionMenu = activeConversation.length === 0;
  const [showConversation, setShowConversation] = useState<boolean>(activeConversation.length > 0);


  useEffect(() => {
    setActiveMenuLinkId("ask-a-doctor");
  }, []);


  const handleHistoricalConversationHeaderOnClick = (conversationIndex: number) => {
    if (shownHistoricalConversationIndex === conversationIndex) {
      setShownHistoricalConversationIndex(null);
    } else {
      setShownHistoricalConversationIndex(conversationIndex);
    }
  }

  const openHeaderSearch = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.getAttribute('id') === "search-trigger") {
      const headerSearchIcon = document.querySelector('#header-search-icon');
      if (headerSearchIcon) {
        (headerSearchIcon as HTMLElement).click();
      }
    }
  }

  const getConversationMentions = (messages: any[]): any => {
    // initial means mentions that were already in the conversation and came from the server
    const initialConversationProductsMentions: ConversationMention[] = [];
    const initialConversationOrdersMentions: ConversationMention[] = [];
    const initialConversationAttachmentsMentions: ConversationMention[] = [];

    for (const message of messages) {
      for (const mention of message.mentions) {
        if (mention.mention_type.includes("Catalog")) {
          initialConversationProductsMentions.push(mention);
        } else if (mention.mention_type.includes("Order")) {
          initialConversationOrdersMentions.push(mention);
        }
      }

      for (const attachment of message.attachments) {
        if (attachment) {
          initialConversationAttachmentsMentions.push(attachment);
        }
      }
    }

    return {
      initialConversationProductsMentions,
      initialConversationOrdersMentions,
      initialConversationAttachmentsMentions,
    };
  };

  return (
    <>
      <div className="account-content">
        <MyAccountContentHeader
          title={translator.translate(language, "my_account", 'AskADoctor/title')}
          description={translator.translate(language, "my_account", 'AskADoctor/subTitle')}
        />

        {showSelectionMenu && <div id="ask-a-doctor-select" className="bg-white rounded-lg p-6">
          <p className='text-[#98A0AF] mb-6 font-poppins text-[16px] font-semibold leading-[16px] text-left'>
            {translator.translate(language, "my_account", 'AskADoctor/select_title')}
          </p>

          <Select
            id={"ask-a-doctor-request"}
            options={askADoctorOptionsNames}
            placeholder={translator.translate(language, "my_account", "AskADoctor/place_holder")}
            questionnaireValidationObject={{}} //passing an empty object because this prop is mandatory for some reason
            errorMode={""}
            onChangeCallback={(optionObj) => {
              setSelectedOption(optionObj.option);
              if (!popupOptions.includes(optionObj.option.id)) {
                setShowConversation(true);
              }else {
                setShowConversation(false);
              }
            }}
          />

          {selectedOption && selectedOption.id && popupOptions.includes(selectedOption.id) ? (
            <Disclaimer
              languageID={language}
              field="my_account"
              text={`AskADoctor/popup_text/${selectedOption.option_name}`}
              containerClassName="p-4 bg-[#FFEBEB] mt-5"
              textClassName="text-[#D51D1D]"
              showIcon={false}
              containerStyle={{ border: "1px solid #D51D1D" }}
              onLinkClick={openHeaderSearch}
            />
          ) : warningOptions.includes(selectedOption?.id || -1) && (
            <Disclaimer
              languageID={language}
              field="my_account"
              text={`AskADoctor/warning_text/${selectedOption?.option_name || ''}`}
              containerClassName="p-4 bg-[#FFF3E6] mt-5"
              textClassName="text-[#EC8F00]"
              showIcon={false}
              containerStyle={{ border: "1px solid #EC8F00" }}
            />
          )}

        </div>}


        {/* Render the only active conversation */}
        {showConversation && (
          <div id="ask-a-doctor-conversation" className="bg-white rounded-lg p-6 mt-8">

            <Conversation
              key={0}
              conversationMessages={activeConversation} //only 1 active
              initialConversationProductsMentions={getConversationMentions(activeConversation).initialConversationProductsMentions}
              initialConversationOrdersMentions={getConversationMentions(activeConversation).initialConversationOrdersMentions}
              initialConversationAttachmentsMentions={getConversationMentions(activeConversation).initialConversationAttachmentsMentions}
              searchProducts={myAccountData.askADoctor.searchProductMentions}
              searchOrders={myAccountData.askADoctor.searchOrderMentions}
              isActive={true}
              selectedIssue={selectedOption?.option_name || activeConversation[0].request_category}
              selectedIssueTranslate={selectedOption?.value || askADoctorOptionsArray.find((option) => option.option_name === (activeConversation[0].request_category))?.[language] as string}
            />

          </div>
        )}

        {/* End of ActiveConversation component */}

        {/* Render the historical conversations */}
        {historicalsConversations.map((historicalConversation: any, index: number) => {

          let conversationID = historicalConversation[0].conversation_id;
          //get the unread doctor messages amount
          let notifcationsAmount = historicalConversation.filter((message: any) => message.read === 0 && message.user_type?.includes("doctor")).length

          return (
            <div className="historical-conversation-accordion" key={index}>
              <HistoricalConverstaionsHeader
                onClick={() => handleHistoricalConversationHeaderOnClick(index)}
                postTime={historicalConversation[0].post_time} //when the first message created
                notificationsAmount={notifcationsAmount}
                conversationID={conversationID}
                selectedIssue={(askADoctorOptionsArray.find(option => option?.option_name === historicalConversation[0].request_category)?.[language] as string) || ''}

              />

              {shownHistoricalConversationIndex === index &&
                <Conversation
                  key={index}
                  conversationMessages={historicalConversation}
                  initialConversationProductsMentions={getConversationMentions(historicalConversation).initialConversationProductsMentions}
                  initialConversationOrdersMentions={getConversationMentions(historicalConversation).initialConversationOrdersMentions}
                  initialConversationAttachmentsMentions={getConversationMentions(historicalConversation).initialConversationAttachmentsMentions}
                  searchProducts={myAccountData.askADoctor.searchProductMentions}
                  searchOrders={myAccountData.askADoctor.searchOrderMentions}
                  isActive={false}
                />
              }
            </div>
          )
        })}
        {/* End of historical conversations */}
      </div>
    </>
  );
};

export default MyAccountAskDoctor;
