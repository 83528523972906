import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CheckoutRegister from "../pages/CheckoutRegister/CheckoutRegister";
import ChooseTreatmentLandingPage from "../pages/ChooseTreatmentVariants/ChooseTreatmentLandingPage/ChooseTreatmentLandingPage";
import PaymentPage from "../pages/Payment/PaymentPage";
import PaymentPageCallBack from "../pages/Payment/PaymentPageCallBack";
import Thankyou from "../pages/Thankyou/Thankyou";
import CheckoutEvaluation from "../pages/CheckoutEvaluation/CheckoutEvaluation";
import Shipping from "../pages/Shipping/Shipping"
import * as React from "react";
import LoadingModal from "../components/Modals/LoadingModal/LoadingModal";
import ServerError from "../components/ServerError/ServerError";
import PageTracker from "../components/PageTracker/PageTracker";
import HeaderController from "../components/Headers/HeaderController";
import ProtectedCustomerRoute from "./ProtectedCustomerRoute";
import Questionnaire from "../pages/Questionnaire/Questionnaire";
import ChooseTreatmentRouter from "../pages/ChooseTreatmentVariants/ChooseTreatmentRouter";
import MyAccountLayout from "../pages/MyAccount/MyAccountLayout";
import MyAccountMyOrders from "../pages/MyAccount/components/MyAccountMyOrders/MyAccountMyOrders";
import MyAccountSubscriptions from "../pages/MyAccount/components/MyAccountSubscriptions/MyAccountSubscriptions";
import MyAccountQuestionnaire from "../pages/MyAccount/components/MyAccountQuestionnaireDefaults/MyAccountQuestionnaireDefaults";
import MyAccountDeliveryAddress from "../pages/MyAccount/components/MyAccountDeliveryAddress/MyAccountDeliveryAddress";
import MyAccountPersonalData from "../pages/MyAccount/components/MyAccountPersonalData/MyAccountPersonalData";
import MyAccountAskADoctor from "../pages/MyAccount/components/MyAccountAskADoctor/MyAccountAskADoctor";
import MyAccountNewsletter from "../pages/MyAccount/components/MyAccountNewsletter/MyAccountNewsletter";
import MyAccountChangePassword from "../pages/MyAccount/components/MyAccountChangePassword/MyAccountChangePassword";
import MyAccountOrderPrescription from "../pages/MyAccount/components/MyAccountOrderPrescription/MyAccountOrderPrescription";
import MyAccountOrderDetails from "../pages/MyAccount/components/MyAccountOrderDetails/MyAccountOrderDetails";
import LoginPage from "../pages/LoginPage/LoginPage";
import ProtectedAuthRoutes from "./ProtectedAuthRoutes";
import RegistrationPage from "../pages/RegistrationPage/RegistrationPage";
import ProtectedMyAccount from "./ProtectedMyAccount";
import MyAccountOrderTreatmentInstructions from "../pages/MyAccount/components/MyAccountOrderTreatmentInstructions/MyAccountOrderTreatmentInstructions";
import ResetPasswordPage from "../pages/ResetPasswordPage/ResetPasswordPage";
import DeactivateAccountConfirmationPage from "../pages/DeactivateAccountConfirmationPage/DeactivateAccountConfirmationPage";
import ChangedEmailConfirmationPage from "../pages/ChangedEmailConfirmationPage/ChangedEmailConfirmationPage";
import QuestionnaireUpdatedConfirmationPage from "../pages/QuestionnaireUpdatedConfirmationPage/QuestionnaireUpdatedConfirmationPage";
import ReorderLayout from "../pages/Reorder/ReorderLayout";
import MedicalDisclaimer from "../pages/Reorder/components/MedicalDisclaimer/MedicalDisclaimer";
import ChooseTreatmentReorder from "../pages/Reorder/components/ChooseTreatmentReorder/ChooseTreatmentReorder";
import ErecipeUpload from "../pages/ErecipeUpload/ErecipeUpload";
import ContactPage from "../pages/ContactPage/ContactPage";

function PagesRouter() {


  return (
    <Router>
      <PageTracker /> {/* Tracker component, listents for url changes, doesnt renders in return */}
      <Routes>
        <Route path="/e-recipe-upload/:country/:language" element={<ErecipeUpload />} />
        <Route path="/questionnaire/:country/:language/:category" element={<Questionnaire />} />
        <Route path="/select_treatment/:country/:language/:category" element={<ChooseTreatmentRouter />} />
        <Route path="/select_treatment_landing_page/:country/:language/:category" element={<ChooseTreatmentLandingPage />} />
        <Route path="/sign_up/:country/:language" element={<CheckoutRegister />} />
        <Route path="/evaluation/:country/:language" element={<CheckoutEvaluation />} />
        <Route path="/shipping/:country/:language/" element={<ProtectedCustomerRoute><Shipping /></ProtectedCustomerRoute>} />
        <Route path="/payment/:country/:language/:order_hash_id/" element={<ProtectedCustomerRoute shouldHaveCart={false}><PaymentPage /></ProtectedCustomerRoute>} />
        <Route path="/payment_page_callback" element={<PaymentPageCallBack />} />
        <Route path="/thank-you/:country/:language/:order_hash_id" element={<Thankyou />} />
        <Route path="/loading" element={<LoadingModal />} />
        <Route path="/server_error" element={<ServerError />} />
        <Route path="*" element={<> <HeaderController showSteps={false} stage={"Questionnaire"} /> <ServerError status={404} /></>} />

        <Route path="/reorder/:order_hash_id/:country/:language/" element={<ProtectedCustomerRoute shouldHaveCart={false}> <ReorderLayout /> </ProtectedCustomerRoute>}>
          <Route path="choose_treatment" element={<ChooseTreatmentReorder />} />
          <Route path="medical_disclaimer" element={<MedicalDisclaimer />} />
        </Route>
        {/* My Account Routes:  */}

        <Route path="/:country/account" element={<ProtectedMyAccount> <MyAccountLayout /> </ProtectedMyAccount>}>

          <Route path="my-orders" element={<MyAccountMyOrders />} />
          <Route path="my-orders/:order_hash_id/details" element={<MyAccountOrderDetails />} />
          <Route path="my-orders/:order_hash_id/prescription" element={<MyAccountOrderPrescription />} />
          <Route path="my-orders/:order_hash_id/treatment-instructions" element={<MyAccountOrderTreatmentInstructions />} />
          {/* <Route path="my-orders/:order_hash_id/confirm-cod" element={<MyAccountConfirmCOD />} /> */}

          <Route path="orders-subscription" element={<MyAccountSubscriptions showOneCardWithEditPanel={false} />} />
          <Route path="orders-subscription/:order_hash_id" element={<MyAccountSubscriptions showOneCardWithEditPanel={true} />} />

          <Route path="questionnaire-defaults" element={<MyAccountQuestionnaire />} />
          <Route path="delivery-address" element={<MyAccountDeliveryAddress />} />
          <Route path="personal-data" element={<MyAccountPersonalData />} />
          <Route path="ask-a-doctor" element={<MyAccountAskADoctor />} />
          <Route path="newsletter" element={<MyAccountNewsletter />} />
          <Route path="change-password" element={<MyAccountChangePassword />} />
        </Route>
        {/* End of My Account Routes:  */}


        <Route path="/:country/contact" element={<ContactPage />} />

        <Route path="/:country/deactivate-account/:token" element={<ProtectedMyAccount><DeactivateAccountConfirmationPage /></ProtectedMyAccount>} />
        <Route path="/:country/change-email/:token" element={<ProtectedMyAccount><ChangedEmailConfirmationPage /></ProtectedMyAccount>} />
        <Route path="/:country/questionnaire-updated/:token" element={<ProtectedMyAccount><QuestionnaireUpdatedConfirmationPage /></ProtectedMyAccount>} />

        {/* Authentication Routes: */}
        <Route path="/:country/register" element={<ProtectedAuthRoutes> <RegistrationPage /> </ProtectedAuthRoutes>} />
        <Route path="/:country/login" element={<ProtectedAuthRoutes> <LoginPage /> </ProtectedAuthRoutes>} />
        <Route path="/:country/reset-password/:token" element={<ProtectedAuthRoutes> <ResetPasswordPage /> </ProtectedAuthRoutes>} />

        {/* End of Authentication Routes: */}

      </Routes>
    </Router>
  );
}

export default PagesRouter;
