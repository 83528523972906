import React from 'react'
import HeaderFunnel from './HeaderFunnel/HeaderFunnel'
import HeaderCMS from './HeaderCMS/HeaderCMS'

function HeaderController({stage, country="", language="",showSteps = true, flow='regular'}) {

  if (!country || !language){
    //find first 2 letters path
    let pathLocale = window.location.pathname.split("/").find(path => path.length === 2)
    if (!country){
      if (pathLocale){
        country = pathLocale
      }
      else{
        country="de"  
      }
    }

    if (!language){
      if (pathLocale){
        language = pathLocale
      }
      else{
        language="de"  
      }
    }
  }
  

  let header
  if(["Questionnaire", "Treatment", "Payment","LoginPage","RegistrationPage"].includes(stage)){
    header = <HeaderFunnel language={language} stage={stage} showSteps={showSteps} flow={flow}/>
  } else {
    header = <HeaderCMS country={country} />
  }
  
  return (
    <div>
      {header}
    </div>
  )
  
}

export default HeaderController