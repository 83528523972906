import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as http from "../../../../../utils/http";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import RadioSelect from '../../../../../components/RadioSelect/RadioSelect';
import { useOutletContext } from 'react-router-dom';
import { MainTracker } from '../../../../../PixelTrackers/MainTracker';
import { MdInfoOutline } from 'react-icons/md';


interface PharmacySelectionProps {
    orderHashID: string
}

interface Pharmacy {
    id: number;
    name: string;
    email: string;
    phone: string;
    zip: string;
    address: string;
    enabled: boolean;
    availability: string;
}

const PharmacySelection: React.FC<PharmacySelectionProps> = ({ orderHashID }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);
    const { setStickyNotificationDetails, setCurrentGlobalModalName, myAccountData } = useOutletContext<any>();
    const orderData = myAccountData.myOrders.ordersData[orderHashID]
    const [selectedPharmacyID, setSelectedPharmacyID] = useState<number | null>(orderData.selectedEPharmacyID);
    const [allowMenuTrigger, setAllowMenuTrigger] = useState<Boolean>(orderData.allowEPharmacyChange);
    const [selectedPharmacy, setSelectedPharmacy] = useState<Pharmacy | null>(null);

    const filteredPharmacies = useMemo(() => {
        return pharmacies.filter((pharmacy) => pharmacy.name?.toLowerCase().includes(searchQuery) || pharmacy?.address?.toLowerCase().includes(searchQuery));
    }, [pharmacies, searchQuery])


    useEffect(() => {
        http.getFromServer(`account/get-external-pharmacies/${orderData.catalogID}`, [http.MIDDLEWARES.CUSTOMER_ID])
            .then((res: any) => {
                setPharmacies(res.data.externalPharmacies || [])
                setSelectedPharmacy(res.data.externalPharmacies.find((pharmacy: Pharmacy) => Number(pharmacy.id) === Number(orderData.selectedEPharmacyID)))
            })
    }, [])

    const onSubmit = (selectedPharmacy: Pharmacy) => {
        if (!selectedPharmacy) {
            return
        }
        const route = "account/update-pharmacy-selection";
        http.postToServer(route, {
            hashID: orderHashID,
            pharmacyID: selectedPharmacy.id
        }, [http.MIDDLEWARES.CUSTOMER_ID])
            .then((res) => {

                const alert = res.data.alert

                setStickyNotificationDetails((currentDetails: any) => ({
                    show: true,
                    title: alert.title,
                    content: alert.content,
                    level: alert.level,
                    showInfoIcon: false,
                    refresh: !currentDetails.refresh
                }))


                if (alert.level === "success") {
                    setSelectedPharmacy(selectedPharmacy);
                    setIsMenuOpen(false);
                }
                
                setAllowMenuTrigger(res.data?.allowEPharmacyChange);
            })
            .catch((err) => {

                if (err.response?.data?.logLevel === "warn"){
                    window.clientLogger.warn("error submit pharmacy selection",err);
                }
                else{
                    window.clientLogger.error("error submit pharmacy selection",err);
                }
                
                setStickyNotificationDetails((currentDetails: any) => ({
                    show: true,
                    title: "Error",
                    content: "Die Apotheke konnte nicht ausgewählt werden.",
                    level: "error",
                    showInfoIcon: false,
                    refresh: !currentDetails.refresh
                }))
            })
            .finally(() => {
                setCurrentGlobalModalName(null);
            })

    }

    const handleMenuTriggerClick = useCallback(() => {
        if (!allowMenuTrigger) {
            return
        }

        //1. Send MP event for the state of the menu (send the opposite state)
        MainTracker.track('custom', 'Click on choose pharmacy.', {
            state: !isMenuOpen ? "opened" : "closed",
            orderID: orderHashID
        });

        //2. Change the state of the menu
        setIsMenuOpen(current => !current);

        //3. clear search
        setSearchQuery("");

    }, [allowMenuTrigger])


    const onSearchQueryChange = useCallback((e: any) => {
        setSearchQuery(e.target.value);
    }, [])

    const handlePharmacySelection = useCallback((pharmacy: Pharmacy) => {

        //1. Send MP event on the select
        MainTracker.track('custom', 'External pharmacy selected', {
            externalPharmacy: pharmacy,
            orderID: orderHashID
        });

        //2. Change the state of the menu + open confirmation modal
        setSelectedPharmacyID(pharmacy.id);
        const modalData = {
            type: "PHARMACY_SELECTION_CONFIRMATION",
            selectedPharmacy: pharmacy,
            onSubmit: () => onSubmit(pharmacy),
        }

        setCurrentGlobalModalName(modalData)
    }, [])

    return (
        <>
            <div id='pharmacy-selection-container' style={{ borderBottom: "1px solid #eef1f6" }} className='bg-white text-sm rounded-[6px]'>
                <div id='pharmacy-selection-header' className='flex flex-col md:flex-row items-center justify-between py-[14px] px-[20px] '>
                    <div className='title-value-container'>
                        <p className='account-order-detail-row-title'> Apotheke </p>
                        <p className='account-order-detail-row-value'>{selectedPharmacy?.name || "-"}</p>
                        {selectedPharmacy && <p className='account-order-detail-row-value'>{selectedPharmacy.address} </p>}

                        {/* Once the order is already sent to pharmacy (allowMenuTrigger is false) we write that in order to inform the customer */}
                        {!allowMenuTrigger && <p className='account-order-detail-row-value break-words mt-[4px]'>* Ihr Rezept wurde genehmigt und an die Apotheke Ihrer Wahl geschickt. Sobald die Apotheke Ihre Bestellung angenommen hat, erhalten Sie eine E-Mail und können das Medikament in der Apotheke Ihrer Wahl bezahlen und abholen. </p>}
                    </div>

                    <div id='change-pharmacy-menu-trigger'
                        onClick={handleMenuTriggerClick}
                        className={` ${allowMenuTrigger ? "bg-[#11DDAC] text-white cursor-pointer" : "bg-gray-200 text-[#9295a0] cursor-not-allowed"} flex items-center justify-center gap-2 mt-[20px] md:mt-0 py-[15px] px-[40px] text-md font-semibold rounded-[3px] whitespace-nowrap h-fit `}>
                        Apotheke auswählen
                        {(isMenuOpen && allowMenuTrigger) ? <FaChevronUp className='mt-[1px]' /> : <FaChevronDown className='mt-[1px]' />}
                    </div>

                    
                </div>

                


                {
                    (isMenuOpen && allowMenuTrigger) ?
                        <>
                            <div id='search-pharmacy-container' style={{ borderTop: "1px solid #eef1f6", borderBottom: "1px solid #eef1f6" }} className='flex flex-col px-[20px] py-[14px] gap-4 select-none'>

                                {/* Search Bar */}
                                <div id='search-pharmacy-input' style={{ border: "1px solid #E5E5E5" }} className='flex items-center gap-2 p-2 rounded-md justify-between '>
                                    <img id='search-icon' className=" bg-[#E7FCF7] rounded-full h-[28px] w-[28px] flex justify-center items-center p-[4px]" src={require('../../../../../icons/Search-Green.svg').default} alt="search" />
                                    <input onChange={onSearchQueryChange} type="text" className='w-full py-2 border-none bg-transparent outline-none text-[14px] text-[#0d2c54] placeholder:text-[16px] placeholder:text-[#9D9D9D] ' placeholder='Hier Eingeben' />
                                </div>

                                {/* Search Bar Info  */}
                                <div id='search-pharmacy-info' className='grid grid-cols-1 lg:grid-cols-2 '>
                                    <span className='font-poppins text-[#9D9D9D] text-[13px] font-normal'>Name der Apotheke, Postleitzahl oder Ort eingeben</span>
                                    {/* <span className='text-center md:text-left text-[13px] text-[#0d2c54] lg:gap-2 '>
                                        Ihre Apotheke nicht gefunden?
                                        <span className='max-md:block text-[#11DDAC] underline cursor-pointer hover:no-underline ml-2 whitespace-break-spaces'>Information Sie Uns.</span>
                                    </span> */}
                                </div>
                            </div>

                            <div id='disclaimer' className='rounded-[3px] flex gap-1 bg-[#E8F1FF] p-3 m-6 text-[#4B91FA] font-roboto font-bold text-[14px]'>
                                <MdInfoOutline size={60} className='text-[#4B91FA] mx-1 h-6' />

                                <p>Nachstehend finden Sie die Verfügbarkeit des von Ihnen ausgewählten Produkts in verschiedenen Apotheken. Die Informationen stammen direkt von der Apotheke. Bei Fragen zur Verfügbarkeit oder zu Aktualisierungen wenden Sie sich bitte direkt an die Apotheke. Sobald Sie eine Apotheke ausgewählt haben, erhalten Sie per E-Mail weitere Anweisungen.</p>
                            </div>


                            {/* All pharmacies options   */}
                            <div
                                id='pharmacy-selection'
                                className='grid grid-cols-1 sm:grid-cols-2 max-h-[325px] my-6 overflow-y-auto bg-white rounded-[3px] px-[20px] gap-2  '
                            >

                                {filteredPharmacies.length > 0 ?
                                    filteredPharmacies.map((pharmacy) => {
                                        return (
                                            <div
                                                key={pharmacy.id}
                                                id={pharmacy.id?.toString()}
                                                className='bg-white cursor-pointer flex items-center gap-4 p-2 select-none'
                                                onClick={() => handlePharmacySelection(pharmacy)}
                                            >
                                                <RadioSelect selected={selectedPharmacyID === pharmacy.id} disabled={false} />

                                                <div id='external-pharmacy-info' className='w-full'>
                                                    <div className='flex justify-between w-full mb-1'>
                                                        <p id='external-pharmacy-name' className='text-[13px] font-medium text-[#0d2c54] leading-[125%]'>{pharmacy?.name}</p>
                                                        <AvailabilityTag availability={pharmacy?.availability} />
                                                    </div>
                                                    <p id='external-pharmacy-address' className='text-[12px] font-normal text-[#0d2c54] mt-[-4px]'>{pharmacy?.address}</p>
                                                </div>
                                            </div>
                                        );
                                    })
                                    :
                                    <p id='external-pharmracy-not-found' className='text-center text-[#0d2c54] font-normal text-[14px]'>
                                        Wir konnten diese Apotheke nicht finden
                                    </p>
                                }
                            </div>
                        </>
                        :
                        null
                }
            </div>


        </>
    )
}


const AvailabilityTag = ({ availability }: { availability: string }) => {
    let className = "px-1 font-poppins text-[13px] font-sm flex-none max-h-[20px] rounded-[5px] font-medium ";
    let text = "";

    switch (availability) {
        case "0":
            className += "bg-[#F9F3C7] text-[#8D7C01]";
            text = "Keine Info" //"No information";
            break;
        case "1":
        case "2":
            className += "bg-[#CFFEDC] text-[#2C8B47]";
            text = "Sofort Verfügbar" //"In stock";
            break;
        default:
            className += "bg-[#FBC7C7] text-[#C40000]";
            text = "Nicht lieferbar" //"Out of stock";
            break;
    }


    return <p className={className}>{text}</p>
}

export default PharmacySelection
