import React, { useEffect } from "react";
import { loadCart } from "../../reducers/CartOrderReducer";
import { MainTracker } from "../../PixelTrackers/MainTracker";
import { useLocationContext } from '../../App';
import { useLocation } from "react-router-dom";

let location = {};

const pageTranslations = {
  questionnaire: "View Questionnaire Page",
  "e-recipe-upload": "View E-recipe-upload page",
  sign_up: "View Checkout Page",
  select_treatment: "View Choose Treatment Page",
  shipping: "View Page: shipping",
  payment: "View Payment Page",
  login:"View Login Page",
  register:"View Registration Page",
  "thank-you": "View Thank You Page"
};


const blacklistPaths = ["reorder"]

const getDeviceType = () => {
  const userAgent = navigator.userAgent;

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
    return "mobile";
  } else if (/iPad/i.test(userAgent)) {
    return "tablet";
  } else {
    return "desktop";
  }
}

const PageTracker = () => {
  const { setLocation } = useLocationContext();
  const fullLocation = useLocation();

  useEffect(() => {
    setLocation(fullLocation)
  }, [fullLocation]);


    if (window.location.pathname !== location.pathname) {
      location.pathname = window.location.pathname;

      let fullPath = location.pathname;
      let splits = fullPath.split("/");
      

      let path
      let site
      // in case of country is the first path
      if (splits[1].length === 2){
        site = splits[1]
        path = splits[2]
      }
      else{
        path = splits[1]
        site = splits[2]
        
      }

      if(blacklistPaths.includes(path)){
        return <></>;
      }

      if (site && site.length === 2){
        MainTracker.superProperties({Site: site.toUpperCase()});
      }
      let translatedPath = pageTranslations[path] || 'View ' +  path + ' Page';
      addSuperProperties(translatedPath,fullPath)
      MainTracker.track("pv", translatedPath);
    }

  window.onpopstate = function (event) {
    MainTracker.track("custom", "Click Go Back", {
      location: location.pathname,
    });
  };

  function addSuperProperties(translatedPath, fullPath) {
    
    let cart
    while(!cart){
      cart = loadCart();
    }
    
    if (translatedPath == "View Choose Treatment Page") {
      MainTracker.superProperties({
        "Product Catalog": cart.category,
        "Product ID": cart.productID,
        "Mixpanel Category Catalog": cart.mixpanel_title,
        "E-Rezept": cart.is_e_recipe ? "Yes" : "No"
      });
    }

    if (
      translatedPath == "View Checkout Page" ||
      translatedPath == "View Page: shipping"
    ) {
      
      let subscriptionPlan;
      if (cart.subscription_plan_id != null) {
        cart.subscription_plan_id == 1
          ? (subscriptionPlan = "1 month")
          : (subscriptionPlan = "3 months");
      } else {
        subscriptionPlan = "No plan";
      }
    

      MainTracker.superProperties({
        "Product Dosage": cart.product_dosage,
        "Product Name": cart.item_title,
        "Product Price": cart.price,
        "Product Price EUR": cart.price,
        "Product Quantity": cart.item_quantity,
        "Subscription type": subscriptionPlan,
      });
    }
    
    MainTracker.superProperties({
      "Device Type": getDeviceType()
    });

  }

  return <></>;
};

export default PageTracker;
