import React, { useState } from 'react';
import "./OTCProductRow.css";
import Information from "../../icons/Information.svg";
import InformationGreen from "../../icons/Information-Green.svg";
import Bin from "../../icons/Bin.svg";
import QuantityControl from '../QuantityControl/QuantityControl';
import { clientConfig } from '../../config';

type OTCProductRowProps = {
    productItem: ProductItem
    countryId: string
    currencySign: string //countryCurrencySign ($)
    currencyRate: number // the rate in the currency of the country (for example in German & Swiss changes)
    OTCAddonsAttributes?: OTCAddonsAttributes | null //The whole OTC addons logic from useOTCaddons
    showBin?: boolean
    quantityProp?: number // can also get it from OTCAddonsAttributes, but some pages like payment shouldn't have The whole logic of OTCAddonsAttributes
    shouldShowQuantityControl?: boolean
}

const OTCProductRow: React.FC<OTCProductRowProps> = ({ 
    productItem,
    countryId,
    currencySign,
    currencyRate,
    OTCAddonsAttributes,
    quantityProp,
    shouldShowQuantityControl = true,
 }) => {

    const [isInformationEnabled, setIsInformationEnabled] = useState<boolean>(false);
    const quantity = quantityProp || OTCAddonsAttributes?.ProductAndQuantityById[productItem.id]?.quantity;

    return (
        <div className='otc-addons-row-container'>
            <div key={productItem.id} className='otc-addons-row'>

                <div className='otc-product-image-and-details'>
                    <img className='otc-product-image' src={clientConfig.IMAGES_URL + productItem.imgSrc} alt="product-item" />
                    <div className='otc-product-item-details'>
                        <p className='product-item-details-title'>{productItem.title}</p>
                        {/* TODO:Translations */}

                        {
                            productItem.custom_quantity_title ?
                                <p className='product-item-details-quantity'>Pack: {productItem.custom_quantity_title}</p>
                                :
                                null
                        }

                        {
                            quantity ?
                                <p className='product-item-details-quantity'>Quantity: {quantity}</p>
                                :
                                null
                        }


                        <p className='product-item-details-price'>{`${(quantity ? productItem.price * currencyRate * quantity : productItem.price * currencyRate).toFixed(2)} ${currencySign}`}</p>
                    </div>
                </div>


                {/* Show the quantityControl only if we have OTCAddonsAttributes */}
                {
                    OTCAddonsAttributes ?

                        <div className='quantity-control-wrapper'>
                            <button onClick={() => setIsInformationEnabled((current: boolean) => !current)} className={`product-information-button${isInformationEnabled ? "-enabled" : ""}`}>
                                <img src={isInformationEnabled ? Information : InformationGreen} alt="i" />
                            </button>

                            {
                                shouldShowQuantityControl ?
                                    <QuantityControl
                                        decrementButtonOnClick={() => OTCAddonsAttributes.decrementQuantity(productItem.id)}
                                        quantity={quantity!}
                                        incrementButtonOnClick={() => OTCAddonsAttributes.incrementQuantity(productItem.id)}
                                    />
                                    :
                                    null
                            }

                                
                            <img onClick={() => OTCAddonsAttributes.removeFromOrder(productItem.id)} className='bin-icon' style={{cursor: "pointer"}} src={Bin} alt="bin" /> 
                        </div>

                        :

                        null
                }

            </div>

            {/* Product's Information text */}
            {
                isInformationEnabled ?
                    <div className='information-text-container'>
                        <p className='information-text'>
                            {productItem.description}
                        </p>
                    </div>
                    :
                    null
            }

        </div>

    )
}

export default OTCProductRow