import axios from "axios";
import { clientConfig } from "../config";
import { loadCart } from "../reducers/CartOrderReducer";
import {loadCustomerFromSession,clearCustomerFromSession} from "../reducers/CustomerReducer"
import * as testsRecorder from "./testsRecorder"

export const MIDDLEWARES = {
  CUSTOMER_ID:"customer_id",
  CART_ORDER: "cart_order",
  GENERATE_FILE: "generate_file"
}

let initialized = false
const initAxios = ()=>{
  if (initialized){
    return
  }
  initialized = true

  //add retry layer
  axios.interceptors.response.use(undefined, (err) => {
    const { config, code } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network Error and there is no response
    if (code !== "ERR_NETWORK" || err.response ) {
      return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, config.retryDelay || 100);
    });
    return delayRetryRequest.then(() => axios(config));
  });
}

//timers
axios.interceptors.request.use(function (config) {
  config.startTime = new Date().getTime()
  return config;
}, function (error) {
  return Promise.reject(error);
})

axios.interceptors.response.use(function (response) {
  response.config.endTime = new Date().getTime()
  response.duration = response.config.endTime - response.config.startTime
  return response;
}, function (error) {
  if (error.response){
    error.response.config.endTime = new Date().getTime();
    error.response.duration = error.response.config.endTime - error.response.config.startTime;
  }
  return Promise.reject(error);
})


function getConfig(middlewares) {
  let config = {
    headers: {'X-Amzn-Trace-Id':new Date().getTime() + "_" + Math.floor(Math.random() * 900000000)},
    withCredentials: true,
  };

  try{
    config.headers.ref_page = window.location.href
    config.headers.uid = window.eventsTracking.getData().uid
    config.headers.ts = new Date().getTime().toString()
    config.headers.ver = clientConfig.VERSION
  }
  catch(err){

  }

  if (middlewares && middlewares.includes(MIDDLEWARES.CUSTOMER_ID)){
    let customer = loadCustomerFromSession(); //get customer id and token
    if (customer && customer.customerID){
      config.headers["customer_id"] = customer.customerID;
      config.headers["token"] = customer.rememberToken;
    }
  }

  if (middlewares && middlewares.includes(MIDDLEWARES.CART_ORDER)){
    let cart = loadCart();
    if (cart && cart.id){
      config.headers["cart_id"] = cart.id;
      config.headers["cart_token"] = cart.token
    }
     
  }

  if (middlewares && middlewares.includes(MIDDLEWARES.GENERATE_FILE)){
      config.responseType = 'blob';
    }
  return config;
}

let recordAllPages = false
const videoTrack = (response) =>{

  const MAX_DATA_LENGTH = 1000
  try{

    let event = {
      headers:response.config.headers,
      url: response.config.url,
      method:response.config.method,
      status:response.status,
      code:response.code,
      message:response.message
    }

    let requestData = response.config.data

    if (requestData){
      event.request_length = requestData.length

      if (requestData.length < MAX_DATA_LENGTH){
        event.request_data = JSON.parse(requestData)
      }
    }

    if (response.data){
      let dataString = JSON.stringify(response.data)

      event.response_length = dataString.length
      if (dataString.length < MAX_DATA_LENGTH){
        event.response_data = response.data
      }
    }

    // save the event to video tracking
    if (window.trackingV && window.trackingV.addEvent){
      window.trackingV.addEvent('http',event)
    }

    try{

      window.eventsTracking.track({
        e:'http_request',
        p:{
          trace_id:response.config.headers['X-Amzn-Trace-Id'],
          duration: response.duration,
          req_url: response.config.url,
          request_length:event.request_length,
          response_length:event.response_length,
          method:response.config.method,
          status:response.status,
          code:response.code
        }
      });
    }
    catch(err){
    }

    // save the event to test recorder
    if (clientConfig.ENV === "development"){

      // record this page or all pages / dependes in the url param
      if (window.location.href.includes("rec=all")){
        recordAllPages = true
      }


      if (window.location.href.includes("rec=page") || recordAllPages ){ 
        // add the full repsonse data that is being trimmed for video tracking
        let recordTestData = {...event}
        if (response.data){
          recordTestData.response_data = response.data
        }
        testsRecorder.addHttpEvent(recordTestData)
      }
    }
  }
  catch(err){
    window.clientLogger.warn("v track error",err)
  }
}

const handlerError = (error,errorStack,errorCallback) =>{

  if (error.response) {

    videoTrack(error.response)
    if (error.response.status === 401){
      clearCustomerFromSession();
      window.location.reload(false);
      return
    }
  }
  else if (error.config){
    // if its axios error ( timeout, then there is no response)
    videoTrack(error)
  }

  if (error?.config?.headers && error.config.headers["X-Amzn-Trace-Id"]){
    error.trace_id = error.config.headers["X-Amzn-Trace-Id"];
  }
  
  error.stack = error.stack + " from " + errorStack.stack
  errorCallback && errorCallback(error);
  
}

export function getFromServer(relativePath,middlewares) {

  initAxios()
  let config = getConfig(middlewares);
  config.retry = 3;

  const call = `${clientConfig.API_URL}/${relativePath}`;

  let stack = new Error()

  return new Promise (function (resolve, reject) {
    axios
    .get(call, config)
    .then((response) =>{
      videoTrack(response)
      resolve(response)
    })
    .catch(error => handlerError(error,stack,reject));
  })

}


export function get(url) {
  initAxios()
  return axios.get(url,{retry:3});

}


export function postToServer(
  relativePath,
  data,middlewares
) {
  let config = getConfig(middlewares);
  axios.defaults.withCredentials = true;

  let stack = new Error()

  return new Promise (function (resolve, reject) {
    axios
    .post(`${clientConfig.API_URL}/${relativePath}`, data, config)
    .then((response) =>{
      videoTrack(response)
      resolve(response)
    })
    .catch(error => handlerError(error,stack,reject));
  })

}





export function postFileToServer(
  relativePath,
  data,
  middlewares
) {
  let config = getConfig(middlewares);
  axios.defaults.withCredentials = true;

  config.headers["Content-Type"] = "application/octet-stream";

  let stack = new Error()

    return new Promise (function (resolve, reject) {
      return axios
      .post(`${clientConfig.API_URL}/${relativePath}`, data, config)
      .then((response) =>{
        videoTrack(response)
        resolve(response)
      })
      .catch(error => handlerError(error,stack,reject));
    })
}

export function postMultiPartsToServer(
  relativePath,
  data,
  files,
  middlewares
) {
  var formData = new FormData();
  
  files.forEach(file=>{
    formData.append("files", new File([file.binary_data], file.filename, { type: file.type }));
  });

  formData.append("document", JSON.stringify(data));

  let config = getConfig(middlewares);
  axios.defaults.withCredentials = true;

  config.headers["Content-Type"] = "multipart/form-data"

  let stack = new Error()

    return new Promise (function (resolve, reject) {
      return axios
      .post(`${clientConfig.API_URL}/${relativePath}`, formData, config)
      .then((response) =>{
        videoTrack(response)
        resolve(response)
      })
      .catch(error => handlerError(error,stack,reject));
    })
}
