
import React, { useContext } from 'react'
import { ChooseTreatmentContext } from '../FreeDosageLayout'
import { FaSearch } from "react-icons/fa";

const ProductSearch = () => {

    const chooseTreatmentContext = useContext(ChooseTreatmentContext);

    return (
        <div className='shadow-lg md:mr-4 rounded-md flex flex-1 items-center bg-white justify-center sticky top-0 w-full'>
            <input className='w-full text-md py-4 px-4 border-none rounded-md focus:outline-none' type="text" onChange={(e) => chooseTreatmentContext?.setSearchTerm(e.target.value)} placeholder='In dieser Kategorie suchen' />
            <div>
                <div className='bg-[#11DDAC] rounded-md w-10 h-10 flex justify-center text-xl m-2 text-white items-center'><FaSearch /></div>
            </div>
        </div>
    )
}

export default ProductSearch
