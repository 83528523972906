const translator = require("./translator.js");

const fieldsRegex = {
  email: {
    regEx:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
  },

  password: {
    regEx: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@+.'"\-?><=/*\\|(),#$%^&*]{7,}$/
  },

  numbersOnly: {
    regEx: /^[0-9]+$/,
  },

  includeNumber: {
    regEx: /[0-9]/,
  },

  /* An optional plus sign at the start, followed by a minimum of 6 digits,
  and then any combination of digits, parentheses, forward slashes, 
  whitespaces, or hyphens to the end of the string.*/
  PhoneSymbols: {
    regEx: /^\+?\d{6,}[0-9()/\s-]*$/   //^(?!.*[\+\-\s])(?=.*[a-zA-Z])(?=.*[^a-zA-Z\s])[a-zA-Z\d!@#\$%\^&\*\(\)_\+\[\]\{\};:\'"\|<,>\.\?\/\\\-]+$  /[A-Za-z!-/:-@[-`{-~-]/ 
  },

  ukPostalCode: {
    regEx: /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? [0-9][A-Za-z]{2}|[Gg][Ii][Rr] 0[Aa]{2})$/
  },
  frPostalCode: {
    regEx:/^(?!00000)[0-9]{5}$/,
  },
  chPostalCode: {
    regEx: /^[0-9]{4}$/,
  },
};

const validatePasswordLogin = (password, languageForErrorMsg) => {
  if (!password || password.length === 0) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_mandatory_field"
      ),
    };
  } else {
    return { isValid: true, errorMsg: "" };
  }
};

const validatePassword = (password, languageForErrorMsg) => {
  if (password.length === 0) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_mandatory_field"
      ),
    };
  }

  if (!fieldsRegex.password.regEx.test(password)) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_enter_valid_data"
      ),
    };
  } else {
    return { isValid: true, errorMsg: "" };
  }
};



const validateRepeatPassword = (
  password,
  repeatPassword,
  languageForErrorMsg
) => {
  if (!repeatPassword || repeatPassword.length === 0) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_mandatory_field"
      ),
    };
  }

  if (password !== repeatPassword) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_repeat_password"
      ),
    };
  } else {
    return { isValid: true, errorMsg: "" };
  }
};

const validateMandatory = (fieldValue, languageForErrorMsg) => {
  if (!fieldValue || fieldValue.length === 0) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_mandatory_field"
      ),
    };
  }

  else return { isValid: true, errorMsg: "" };
};



const validateCompany = (fieldValue, languageForErrorMsg) => {
  //not a mandatory field!
  if (!fieldValue || fieldValue.length < 35) {
    return {
      isValid: true,
      errorMsg: "",
    };
  } else {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_enter_valid_data"
      ),
    };
  }
};

const validateName = (fieldValue, languageForErrorMsg) => {
  if (!fieldValue || fieldValue.length === 0) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_mandatory_field"
      ),
    };
  }

  if (/[0-9]/.test(fieldValue) || fieldValue.length === 1) {
    //if name size is 1 or consists digits
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_enter_valid_data"
      ),
    };
  } else return { isValid: true, errorMsg: "" };
};

const validateEmail = (email, languageForErrorMsg) => {
  if (!email || email.length === 0) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_mandatory_field"
      ),
    };
  }

  if (!fieldsRegex.email.regEx.test(email))
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_enter_valid_data"
      ),
    };
  else return { isValid: true, errorMsg: "" };
};

const validatePhone = (phone, languageForErrorMsg) => {
  if (!phone || phone.length === 0) {
    return { "isValid": false, "errorMsg": translator.translate(languageForErrorMsg, "default", "LoginQuestion/validation_mandatory_field") };
  }
  else if (fieldsRegex.PhoneSymbols.regEx.test(phone)) {
    return { "isValid": true, "errorMsg": "" };
  }
  else {
    return { "isValid": false, "errorMsg": translator.translate(languageForErrorMsg, "shipping", "ShippingForm/validation_phone") };
  }
}


const validatePostalCode = (postalCode, languageForErrorMsg, destionationCountry) => {
  if (destionationCountry === "de") {
    if (!postalCode || postalCode.length === 0) {
      return { "isValid": false, "errorMsg": translator.translate(languageForErrorMsg, "default", "LoginQuestion/validation_mandatory_field") };
    }

    else if (postalCode.length !== 5 || !fieldsRegex.numbersOnly.regEx.test(postalCode)) {
      return { "isValid": false, "errorMsg": translator.translate(languageForErrorMsg, "shipping", "ShippingForm/validation_postal_code") };
    }

    else {
      return { "isValid": true, "errorMsg": "" }
    }
  }

  if (destionationCountry === "ch") {
    if (!postalCode || postalCode.length === 0) {
      return { "isValid": false, "errorMsg": translator.translate(languageForErrorMsg, "default", "LoginQuestion/validation_mandatory_field") };
    }
  
    else if (!fieldsRegex.chPostalCode.regEx.test(postalCode)) {
      return { "isValid": false, "errorMsg": translator.translate(languageForErrorMsg, "shipping", "ShippingForm/validation_postal_code_ch") };
    }
  
    else {
      return { "isValid": true, "errorMsg": "" };
    }
  }


  if (destionationCountry === "en") {
    if (!postalCode || postalCode.length === 0) {
      return {
        isValid: false,
        errorMsg: translator.translate(
          languageForErrorMsg,
          "default",
          "LoginQuestion/validation_mandatory_field"
        ),
      };
    } else if (!fieldsRegex.ukPostalCode.regEx.test(postalCode)) {
      return {
        isValid: false,
        errorMsg: translator.translate(
          languageForErrorMsg,
          "shipping",
          "ShippingForm/validation_postal_code"
        ),
      };
    } else {
      return { isValid: true, errorMsg: "" };
    }
  }
  if (destionationCountry === "fr") {
    if (!postalCode || postalCode.length === 0) {
      return {
        isValid: false,
        errorMsg: translator.translate(
          languageForErrorMsg,
          "default",
          "LoginQuestion/validation_mandatory_field"
        ),
      };
    } else if (
      postalCode.length !== 5 ||
      !fieldsRegex.frPostalCode.regEx.test(postalCode)
    ) {
      return {
        isValid: false,
        errorMsg: translator.translate(
          languageForErrorMsg,
          "shipping",
          "ShippingForm/validation_postal_code"
        ),
      };
    } else {
      return { isValid: true, errorMsg: "" };
    }
  } else {
    return { isValid: true, errorMsg: "" };
  }
};

const validateStreetAndNumber = (streetAndNumber, languageForErrorMsg) => {
  if (!streetAndNumber || streetAndNumber.length === 0) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_mandatory_field"
      ),
    };
  } else if (
    streetAndNumber.length === 1 ||
    !fieldsRegex.includeNumber.regEx.test(streetAndNumber)
  ) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_enter_valid_data"
      ),
    };
  } else {
    return { isValid: true, errorMsg: "" };
  }
};

const validatePackstationNumber = (packstationNumber, languageForErrorMsg) => {
  //packstation is a string because it's an input
  if (!packstationNumber || packstationNumber.length === 0) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_mandatory_field"
      ),
    };
  } else if (
    packstationNumber < 10 ||
    !fieldsRegex.numbersOnly.regEx.test(packstationNumber)
  ) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_enter_valid_data"
      ),
    };
  } else {
    return { isValid: true, errorMsg: "" };
  }
};

const validatePostNumber = (postNumber, languageForErrorMsg) => {
  //postNumber is a string because it's an input
  if (!postNumber || postNumber.length === 0) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_mandatory_field"
      ),
    };
  } else if (
    postNumber.length < 6 ||
    !fieldsRegex.numbersOnly.regEx.test(postNumber)
  ) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_enter_valid_data"
      ),
    };
  } else {
    return { isValid: true, errorMsg: "" };
  }
};




const areQuestionsFilled = (questionsValidationObject) => {
  let questionsFilled = true;

  for (let key of Object.keys(questionsValidationObject)) {
    if (!questionsValidationObject[key].isFilled) questionsFilled = false;
  }

  return questionsFilled;
};

const areQuestionsValid = (questionsValidationObject) => {
  let questionsValid = true;

  for (let key of Object.keys(questionsValidationObject)) {
    if (!questionsValidationObject[key].isValid) questionsValid = false;
  }

  return questionsValid;
};

const areSubQuestionsFilled = (subQuestionsValidationObject) => {
  let subQuestionsFilled = true;

  for (let key of Object.keys(subQuestionsValidationObject)) {
    if (
      !subQuestionsValidationObject[key].isFilled &&
      subQuestionsValidationObject[key].isDisplayed
    )
      subQuestionsFilled = false;
  }

  return subQuestionsFilled;
};

const areSubQuestionsValid = (subQuestionsValidationObject) => {
  let subQuestionsValid = true;

  for (let key of Object.keys(subQuestionsValidationObject)) {
    if (
      !subQuestionsValidationObject[key].isValid &&
      subQuestionsValidationObject[key].isDisplayed
    )
      subQuestionsValid = false;
  }

  return subQuestionsValid;
};

const validateMessage = (message, languageForErrorMsg) => {
  if (!message || message.length < 20) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "contact",
        "ContactPageForm/message_error"
      ),
    };
  } else {
    return { isValid: true, errorMsg: "" };
  }
};

const validHashID = (hashID, languageForErrorMsg) => {
  if (!hashID || hashID.length !== 7) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "contact",
        "ContactPageForm/order_number_error"
      ),
    };
  } else {
    return { isValid: true, errorMsg: "" };
  }
}

const validateByField = (field, value, language, destionationCountry) => {
  switch (field) {
    case "email":
      return validateEmail(value, language);

    case "password":
    case "old_password":
      return validatePassword(value, language);


    case "packstation_address":
        return validatePackstationAddress(value, language);
    case "packstation_personal_address":
    case "address":
    case "billing_address":

      return validateStreetAndNumber(value, language);

    case "first_name":
    case "billing_first_name":
    case "packstation_personal_first_name":
    case "last_name":
    case "billing_last_name":
    case "packstation_personal_last_name":
    case "city":
    case "street":
    case "packstation_personal_city":
    case "packstation_city":
    case "billing_city":
    case "nameAndSurname":
      return validateName(value, language);

    case "zip":
    case "billing_zip":
    case "packstation_zip":
    case "packstation_personal_zip":
    case "postal":
      return validatePostalCode(value, language, destionationCountry);

    case "company":
      return validateCompany(value, language);

    case "phone":
    case "packstation_personal_phone":
      return validatePhone(value, language);


    case "packstation_number":
      return validatePackstationNumber(value, language);

    case "day":
    case "loginPassword":
    case "month":
    case "year":
    case "gender":
    case "agree_to_terms":
    case "house_number":
      return validateMandatory(value, language);

    case "message":
    case "content":
      return validateMessage(value, language);
  
    case "orderNumber":
      return validHashID(value, language);

    default:
      return { isValid: true, errorMsg: "" };
  }
};

const validatePackstationAddress = (address, languageForErrorMsg) => {
  if (!address || address.length === 0) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_mandatory_field"
      ),
    };
  } else if (
    !fieldsRegex.numbersOnly.regEx.test(address)
  ) {
    return {
      isValid: false,
      errorMsg: translator.translate(
        languageForErrorMsg,
        "default",
        "LoginQuestion/validation_enter_valid_data"
      ),
    };
  } else {
    return { isValid: true, errorMsg: "" };
  }
};

module.exports = {
  validateByField,
  validateName,
  validatePassword,
  validatePasswordLogin,
  validateEmail,
  validateMandatory,
  validateRepeatPassword,
  validatePostalCode,
  validateStreetAndNumber,
  validatePackstationNumber,
  validatePostNumber,
  validatePhone,
  areQuestionsFilled,
  areQuestionsValid,
  areSubQuestionsFilled,
  areSubQuestionsValid,
  validatePackstationAddress,
};
