import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import "./MedicalDisclaimer.css";
import * as http from "../../../../utils/http";
import * as translator from "../../../../utils/translator";
import { useCartContext } from "../../../../reducers/CartOrderReducer";
import Questionnaire from "../../../Questionnaire/Questionnaire";
import Significance from "../../../Questionnaire/components/Significance/Significance";
import { MainTracker } from "../../../../PixelTrackers/MainTracker";

type QuestionnaireSettingsType = {
  show: boolean;
  prefill: boolean;
};

const MedicalDisclaimer = () => {
  const navigate = useNavigate();
  const { reorderData, language, country, orderHashID, setHeaderStep } = useOutletContext<any>();
  const { cartDispatch } = useCartContext();
  const [questionnaireSettings, setQuestionnaireSettings] = useState<QuestionnaireSettingsType>({
    show: false,
    prefill: false,
  });

  useEffect(() => {
    setHeaderStep("Questionnaire");
    MainTracker.track("pv","Medical-change---Reorder");
  }, [])


  const navigateToChooseTreatment = () => {
    if (reorderData.isOrderProductItemInStock) {
      navigate({ pathname: `/reorder/${orderHashID}/${country}/${language}/choose_treatment/` });
    } else {
      navigate({ pathname: `/select_treatment/${country}/${language}/${reorderData.catalogMention}` });
    }
  };

  const onSubmit = () => {
    // Only if health status didn't change (user pressed NO)

    //Since we need to create a cart like a normal flow, this block uses that same post of the questionnaire, just passing the previous's order's questionnaire in the body.
    http
      .postMultiPartsToServer(`questionnaire/${country}/${language}/${reorderData.catalogMention}`, {
        questionnaire: JSON.parse(reorderData.rawQuestionnaire),
        mention: reorderData.catalogMention,
        isBlocking: false,
        country: country,
        language: language,
        allowOnlyPrescription: false,
        isExpressReorder:true,
        
      }, [], [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.CART_ORDER])
      .then((response) => {
        cartDispatch({
          type: "store",
          cart: {
            id: response.data.cartID,
            token: response.data.cartToken,
            category: reorderData.catalogMention,
            mixpanel_title: response.data.catalog.mixpanel_title
          },
        });
        navigateToChooseTreatment();
      })
      .catch((err) => {
        window.clientLogger.error("error postToServer MedicalDisclaimerPage: ", err);
      });
  };

  if (questionnaireSettings.show) {
    return (
      <div className="reorder-questionnaire-container">


        {/* We want to show the significance that tells that customer that he can edit only if the questionnaire is prefilled */}
        {questionnaireSettings.prefill ? 
          <Significance 
          id={"reorder-questionnaire-significance"}
          dangerSignificancesRefsDispatch={null} 
          parentID={null} 
          showIcon 
          level={"success"} 
          fontWeight={400}
          fontSize={'16px'} 
          text={translator.translate(language, "reorder", "MedicalDisclaimer/questionnaire_significance")}
          />
          :
          null
        }
        
        <Questionnaire
          prefilledQuestionnaire={questionnaireSettings.prefill ? reorderData.prefilledQuestionnaire : null}
          overrideQuestionnaireParams={{ language, country, optionalCategory: reorderData.catalogMention }}
          navigationOverride={navigateToChooseTreatment}
        />
      </div>
    );

  } else {
    return (
      <div className="medical-disclaimer-container">
        <p>{translator.translate(language, "reorder", "MedicalDisclaimer/order_ready")}</p>
        <h3>{translator.translate(language, "reorder", "MedicalDisclaimer/health_status_changed")}</h3>


        {/* questionnaire hasn't changed button (NO)*/}
        <button id="medical-disclaimer-no-button" onClick={() => onSubmit()}>
          {translator.translate(language, "reorder", "MedicalDisclaimer/no")}
        </button>

        {/* questionnaire has changed button (YES)*/}
        <button
          id="medical-disclaimer-yes-button"
          onClick={() => {
            //mp event
            MainTracker.track("pv","View-previous-questionnaire---Reorder");
            setQuestionnaireSettings((current) => ({ ...current, show: true, prefill: false }))}
          }>

          {translator.translate(language, "reorder", "MedicalDisclaimer/yes")}
        </button>


        {/* preview my old questionnaire (will prefill questionnaire) */}
        <span
          onClick={() => setQuestionnaireSettings((current) => ({ ...current, show: true, prefill: true }))}
          className="view-previous-questionnaire-link">
          {translator.translate(language, "reorder", "MedicalDisclaimer/view_prev_questionnaire")}
        </span>
      </div>
    );
  }
};

export default MedicalDisclaimer;
