import React from 'react';
import { MdInfoOutline } from "react-icons/md";
import * as translator from "../../../../utils/translator";

type Props = {
    languageID: string,
    field: string,
    text: string,
    containerClassName?: string,
    textClassName?: string,
    showIcon?: boolean,
    containerStyle?: React.CSSProperties,
    onLinkClick?: (e: React.MouseEvent) => void
};

const Disclaimer: React.FC<Props> = ({ languageID, field, text, containerClassName = "", textClassName = "", showIcon = true, containerStyle = {}, onLinkClick }) => {

    // Handle click events in the container
    
    return (
        <div id='disclaimer' className={`p-2 bg-[#E8F1FF] rounded-md flex items-start text-lg gap-1 w-full ${containerClassName}`} style={containerStyle}>
            {showIcon &&
                <MdInfoOutline size={60} className='text-[#4B91FA] mx-1 h-6'/>}
            <div onClick={onLinkClick}>
                <div dangerouslySetInnerHTML={{ __html: translator.translate(languageID, `${field}`, `${text}`) }} className={`text-[#4B91FA] font-semibold text-sm ${textClassName}`}></div>
            </div>
        </div>
    );
};

export default Disclaimer