let translationCache = {}

export function translate (language, category, path){

    if (!translationCache[language]){
        translationCache[language] = {}
    }

    if (!translationCache[language][category]){
        translationCache[language][category] = require(`../../resources/translations/${language}/${category}/translations.json`)
    }

    let paths = path.split("/")

    let currJson = translationCache[language][category]

    paths.forEach(currKey =>{currJson = currJson[currKey]} )

    return currJson
}


export const getCountryByLanguage = (language)=>{
  switch (language){
    case "de":
      return "DE";
    case "fr":
      return "FR";
    case "en":
      return "GB"
  }
}

export const getLanguageByCountry = (country)=>{
  switch (country?.toLowerCase()){
    case "de" || "DE":
      return "de";
    case "fr" || "FR":
      return "fr";
    default:
      return "en"
  }
}

export const getCurrencyByCountry = (country, shouldReturnSymbol = true) => {
  // Convert country to lowercase for consistent comparison
  const countryLowerCase = country?.toLowerCase();
  

  switch (countryLowerCase) {
    case "de":
    case "fr":
      return shouldReturnSymbol ? "€" : "EUR";

    case "gb":
    case "uk":
    case "en":
      return shouldReturnSymbol ? "£" : "GBP";

    case "ch":
      return shouldReturnSymbol ? "₣" : "CHF";

    default:
      return shouldReturnSymbol ? "$" : "USD";
  }
};

export const formatNumberByLocale = (number, locale = "en-US", fallback = "en-US") => {
  if (typeof number !== "number") {
    number = 0;
  }
  if (typeof number === "string" && !isNaN(parseFloat(number))) {
    number = parseFloat(number);
  }
  try {
    return number.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  } catch (error) {
    return number.toLocaleString(fallback, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
}